.active-tab {
    background: #D3E3F9;
}

.inacive-tab {
    background: transparent;
}

.usertab {
    height: 28px;
    left: -1.63%;
    right: -1.62%;
    cursor: pointer;
    display: flex;
    width: 170px;
    top: 0px;
    /* border: 0.8px solid #222222; */
}

.convert-link {
    cursor: pointer;
}

.tab-close {
    background: transparent;
    line-height: 32px;
    font-size: 13px;
}

.customdropdown {
    position: absolute;
    top: 17px!important;
    left: 0px!important;
    will-change: top, left;
}

.detailBg {
    background: linear-gradient( 180deg, #E1EEFF 0%, #5383C4 100%);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: calc(100vh - 48px);
    max-height: calc(100vh - 48px);
    padding-bottom: 24px;
}

.tab-sub-header {
    text-align: left;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.tab-title {
    font-weight: 400;
    font-size: 10.5px;
    text-align: left;
    width: 150px;
    padding: 0PX 10px;
    margin: 0px;
    line-height: 28px;
}

.tab-table-container {
    margin: 16px;
    border-radius: 10px;
}

.data-container {
    padding: 0px 10px;
}

.addTag {
    font-size: 12px;
    margin: 0px;
    color: #0077BE;
    padding: 0px 2px;
    ;
}

.tag-container {
    padding: 12px 0px 0px 0px!important;
    border: none!important;
    margin: 20px 10px 0px 20px!important;
}

.accor-margin {
    /* margin-top: 16px; */
}

.accor-title {
    text-align: left;
    margin: 0px!important;
}

.detailBg.listdetails .content .card.card-content+.card.card-table {
    flex: 1;
    margin-bottom: 12px !important;
}

.detailBg.listdetails .content {
    min-height: calc(100vh - 108px);
    max-height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 24px;
}

.detailBg.listdetails>.breadcrumb-wrapper {
    flex-shrink: 0;
}

.tabview-list {
    background: #fff;
}

.detailBg.listdetails {
    padding: 0;
    min-height: initial;
    max-height: initial;
    overflow: hidden;
}

.addTag svg {
    font-size: 9px;
}