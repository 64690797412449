.activity-drawer {
  z-index: 98 !important;
}
.activity-drawer .MuiPaper-elevation16 {
  box-shadow: none;
  background: #f8fafb;
  border: 1px solid #edeef1;
  background: transparent;
  border: none;
  top: 48px;
  min-height: calc(100vh - 74px);
  max-height: calc(100vh - 74px);
  top: 50px;
}

.activity-drawer .activity-popup {
  min-height: calc(100vh - 74px);
  max-height: calc(100vh - 74px);
  background: #f8fafb;
  border: 1px solid #edeef1;
  display: flex;
  flex-direction: column;
  border-left: 2px solid #d2d2d2;
}

.activity-drawer .activity-popup .activity-head {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background: #ffffff;
  flex-shrink: 0;
}

.activity-drawer .activity-popup .activity-list {
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  padding: 12px;
  flex: 1;
}

.activity-drawer .activity-popup .activity-head .dropdown .link {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.activity-drawer .activity-popup .activity-head .righ-container {
  flex: 1;
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.activity-drawer .activity-popup .activity-head .righ-container > div {
  margin-left: 8px !important;
}

.activity-drawer
  .activity-popup
  .activity-head
  .righ-container
  .iconbutton
  button {
  height: 24px;
  width: 28px;
}

.activity-drawer .activity-popup .activity-head .dropdown .dropdown-menu {
  right: auto !important;
  left: 0 !important;
  top: 100% !important;
}

.activity-drawer .activity-popup .activity-list .item {
  background: #ffffff;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin-bottom: 10px;
  padding: 12px;
}

.activity-drawer .activity-popup .activity-list .item .tag-line {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.activity-drawer .activity-popup .activity-list .item .details {
}

.activity-drawer .activity-popup .activity-list .item .tag-line .tag {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  border-radius: 4px;
  padding: 0px 8px;
}

.activity-drawer .activity-popup .activity-list .item .tag-line .dropdown a {
  cursor: pointer;
}

.activity-drawer
  .activity-popup
  .activity-list
  .item
  .tag-line
  .dropdown
  .dropdown-menu {
  top: 100% !important;
}

.activity-drawer .activity-popup .activity-list .item .details .link {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin-bottom: 8px;
  display: inline-block;
}

.activity-drawer .activity-popup .activity-list .item .details .date-time {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.activity-drawer .activity-popup .activity-list .item .details .date-time .date,
.activity-drawer
  .activity-popup
  .activity-list
  .item
  .details
  .date-time
  .time {
  margin-right: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #222222;
}

.activity-drawer
  .activity-popup
  .activity-list
  .item
  .details
  .date-time
  .date
  .icon,
.activity-drawer
  .activity-popup
  .activity-list
  .item
  .details
  .date-time
  .time
  .icon {
  position: relative;
  top: 2px;
  margin-right: 4px;
}

.activity-drawer .activity-popup .activity-list .item .details .attributes {
}

.activity-drawer
  .activity-popup
  .activity-list
  .item
  .details
  .attributes
  .attribute-list {
}

.activity-drawer
  .activity-popup
  .activity-list
  .item
  .details
  .attributes
  .attribute-list
  .sub {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    min-width: auto;
    display: inline-block;
}

.activity-drawer
  .activity-popup
  .activity-list
  .item
  .details
  .attributes
  .attribute-list
  .dot {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #222;
  margin: 0 4px;
}

.activity-drawer
  .activity-popup
  .activity-list
  .item
  .details
  .attributes
  .attribute-list
  .text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
}

.activity-drawer .activity-popup .activity-list .item .tag-line .dropdown {
  line-height: 20px;
  top: 2px;
}

.tag-line .tag.grey {
  background: #888888;
}

.tag-line .tag.red {
  background: #df6363;
}

.activity-drawer .activity-popup .activity-list .item:last-child {
  margin-bottom: 0;
}
.activity-drawer .activity-popup .activity-head p.head {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  color: #222222;
  margin: 0;
  text-align: left;
  width: 100%;
}
.inner-navmenu .navmenu-list ul {
  z-index: 97 !important;
}

.activity-list.timeline-list .item-head {
  margin-bottom: 10px;
  position: relative;
  z-index: 2;
}

.activity-list.timeline-list .timeline-item {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.activity-list.timeline-list .item-head .date {
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-align: center;
  color: #222222;
  background: #e0e5f3;
  border-radius: 4px 12px;
  padding: 1px 12px;
  width: 105px;
  display: inline-block;
  position: relative;
  z-index: 1;
}

.activity-list.timeline-list .timeline-item .icon-box {
  display: inline-block;
  width: 40px;
  height: 40px;
  background: #ffffff;
  border: 1px solid #edeef1;
  box-sizing: border-box;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}

.activity-list.timeline-list .timeline-item .item-box {
  background: #ffffff;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  padding: 12px;
  flex: 1;
}

.activity-list.timeline-list .timeline-item .item-box .flex-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activity-list.timeline-list .timeline-item .item-box p.text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin: 0;
}

.activity-list.timeline-list .timeline-item .item-box .flex-block p.type {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 4px;
  word-break: break-word;
}

.activity-list.timeline-list
  .timeline-item
  .item-box
  .flex-block
  .right-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.activity-list.timeline-list
  .timeline-item
  .item-box
  .flex-block
  .right-container
  .datetime {
  font-weight: normal;
  font-size: 12.5px;
  line-height: 20px;
  text-align: right;
  color: #555555;
  margin-right: 8px;
  min-width: 150px;
  
}

.activity-list.timeline-list
  .timeline-item
  .item-box
  .flex-block
  .right-container
  .dropdown {
  line-height: 20px;
}

.activity-list.timeline-list .item-head:before {
  content: "";
  position: absolute;
  left: 0;
  top: 13px;
  height: 1px;
  width: 100%;
  background: #edeef1;
}

.activity-list.timeline-list:before {
  content: "";
  position: absolute;
  left: 31px;
  top: 0;
  width: 1px;
  height: 100%;
  background: #edeef1;
}

.activity-list.timeline-list
  .timeline-item
  .item-box
  .flex-block
  .right-container
  .dropdown
  > a {
  position: relative;
  top: 1px;
  cursor: pointer;
}
.activity-drawer .activity-popup .activity-head .link .icon {
  margin-right: 6px;
  position: relative;
  top: 1px;
}

.activity-drawer .activity-popup .activity-head > .link {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.activity-drawer .activity-popup .activity-head .link .icon svg {
  height: 11px;
}
.activity-drawer .activity-popup .activity-head .link .icon {
  margin-right: 6px;
  position: relative;
  top: 1px;
}

.activity-drawer .activity-popup .activity-head > .link {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.activity-drawer .activity-popup .activity-head .link .icon svg {
  height: 11px;
}

.activity-list.activity-detail .tag-line {
  margin-bottom: 12px !important;
}

.activity-list.activity-detail .item .details .row-block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #EDEEF1;
}

.activity-list.activity-detail .item .details .row-block .text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #222222;
  margin: 0;
}

.activity-list.activity-detail .item .details .row-block .attribute {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.activity-list.activity-detail .item .details .row-block .attribute .sub {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #555555;
}

.activity-list.activity-detail .item .details .row-block .attribute .text {
}

.activity-list.activity-detail
  .item
  .details
  .row-block
  .attribute
  .text
  .icon {
    position: relative;
    top: -1px;
    margin-left: 6px;
}

.activity-list.activity-detail
  .item
  .details
  .row-block
  .fieldset.checkbox-box
  .input-group
  input {
  position: relative;
  top: 5px;
  margin-right: 8px;
}

.activity-list.activity-detail .item .details .row-block .text-block {
  display: flex;
  align-items: center;
}

.activity-list.activity-detail .item .details .row-block .text-block .sub {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #555555;
  margin-right: 4px;
}

.activity-list.activity-detail .item .details .row-block .text-block .t-box {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #707070;
  margin-right: 6px;
  padding-right: 6px;
  position: relative;
}

.activity-list.activity-detail
  .item
  .details
  .row-block
  .attribute
  .sub
  .icon-padding {
  position: relative;
  top: 1px;
}

.activity-list.activity-detail
  .item
  .details
  .row-block
  .fieldset.checkbox-box
  > label.control-label {
  display: none;
}

.activity-list.activity-detail
  .item
  .details
  .row-block
  .fieldset.checkbox-box
  .input-group
  label {
  margin: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  color: #222222;
}

.activity-list.activity-detail
  .item
  .details
  .row-block
  .attribute
  .dropdown
  > a {
  font-size: 14px;
  line-height: 20px;
}

.activity-list.activity-detail
  .item
  .details
  .row-block
  .text-block
  .t-box:before {
  content: "";
  position: absolute;
  right: 0;
  top: 20%;
  width: 1px;
  height: 60%;
  background: #707070;
}

.activity-list.activity-detail
  .item
  .details
  .row-block
  .text-block
  .t-box:last-child:before {
  background: transparent;
}

.activity-list.activity-detail .item .details .row-block:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.activity-list.activity-detail
  .item
  .details
  .row-block
  .attribute
  .dropdown
  .dropdown-menu {
    bottom: 100% !important;
    top: auto !important;
    left: 0% !important;
    transform-origin: bottom left;
}

.activity-create .MuiDialog-container .MuiPaper-elevation24 {
  width: 800px !important;
  max-width: 75%;
  /* max-height: 600px !important ; */
}

.activity-create .activity-form {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 form > div {
  padding: 16px;
  max-height: 440px !important;
}
.activity-create .activity-form .fieldset {
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 12px;
}

.activity-create .MuiDialogActions-spacing .item {
  width: auto !important;
}

.activity-create .activity-form .fieldset:nth-child(2n) {
  padding-left: 8px;
}

.activity-create .activity-form .fieldset:nth-child(2n + 1) {
  padding-right: 8px;
}

.activity-create .activity-form .fieldset.checkbox-box .input-group input {
  position: relative;
  top: 6px;
  margin-right: 6px;
  cursor: pointer;
}

.activity-create
  .activity-form
  .fieldset.checkbox-box
  .input-group
  label.control-label {
  margin: 0;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;

  color: #222222;
  cursor: pointer;
}

@media (max-width: 1366px) {
  .activity-create .MuiDialog-container .MuiPaper-elevation24 {
    max-height: calc(100vh - 100px);
  }
}
.activity-drawer.notification-drawer {
  z-index: 99 !important;
}
.activity-list.timeline-list.emptycontent:before {
  background: transparent;
}

.activity-list.timeline-list.emptycontent .fullwidth {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  width: 100%;
}

.activity-list.timeline-list.emptycontent .fullwidth .icon-box {
  margin-bottom: 30px;
}

.activity-list.timeline-list.emptycontent .fullwidth p.text.bold {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.activity-list.timeline-list.emptycontent .fullwidth p.text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #555555;
  margin-bottom: 6px;
}
.activity-list.timeline-list.emptycontent:before {
  background: transparent;
}

.activity-list.timeline-list.emptycontent .fullwidth {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 !important;
  width: 100%;
}

.activity-list.timeline-list.emptycontent .fullwidth .icon-box {
  margin-bottom: 30px;
}

.activity-list.timeline-list.emptycontent .fullwidth p.text.bold {
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.activity-list.timeline-list.emptycontent .fullwidth p.text {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #555555;
  margin-bottom: 6px;
}

.activity-list.conversation-wrapper {
  padding: 0 !important;
}

.activity-list.conversation-wrapper ul.list-block {
  padding: 12px;
}

.activity-list.conversation-wrapper ul.list-block li {
  background: #ffffff;
  box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  border-bottom: none;
  margin-bottom: 12px;
  padding: 12px;
}

.activity-list.conversation-wrapper ul.list-block li:last-child {
  margin-bottom: 0;
}
.activity-list.conversation-content .conversation-wrapper .list-block {
  padding: 0;
  background: #fff;
  border-radius: 8px;
}

.activity-list.conversation-content .conversation-wrapper .list-block .item {
  padding: 16px 0;
  box-shadow: none;
  margin: 0 16px;
}
.conversation-content
  .conversation-wrapper
  .list-block
  .item
  .right-container
  .dropdown {
  position: relative;
  top: 2px;
  cursor: pointer;
}
.activity-drawer
  .activity-popup
  .activity-head
  .righ-container
  button.btnsml.min_width {
  width: 28px;
  padding: 0 !important;
}

.activity-drawer
  .activity-popup
  .activity-head
  .righ-container
  button.btnsml.min_width
  svg {
  width: 14px;
}

.activity-list.activity-detail .item .details .row-block.full-block {
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 0;
}

.activity-list.activity-detail .item .details .row-block.full-block .attribute {
  margin-bottom: 12px;
}
.activity-list.activity-detail .item .details .row-block .attribute .dropdown .dropdown-menu:before {
  border-top: 10px solid #FFE;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: none;
  top: auto !important;
  bottom: -10px;
  left: 10px;
  right: auto !important;
}
.activity-drawer .activity-popup .activity-head .righ-container > .link {
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}