.active-tab {
    background: #d3e3f9;
}

.inacive-tab {
    background: transparent;
}

.psw-icon {
    z-index: 3;
}

input[type="checkbox" i]:disabled {
    cursor: default !important;
}
.usertab {
    height: 28px;
    left: -1.63%;
    right: -1.62%;
    cursor: pointer;
    display: flex;
    width: 170px;
    top: 0px;
    /* border: 0.8px solid #222222; */
}

.tab-close {
    background: transparent;
    line-height: 32px;
    font-size: 13px;
}

.capitalize {
    text-transform: capitalize;
}

.assigndialog {
    width: 100%;
}

.detailBg {
    background: linear-gradient(180deg, #e1eeff 0%, #5383c4 100%);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: calc(100vh - 48px);
    max-height: calc(100vh - 48px);
    padding-bottom: 24px;
}

.tab-sub-header {
    text-align: left;
    padding: 12px 16px;
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.tab-title {
    font-weight: 400;
    font-size: 10.5px;
    text-align: left;
    width: 150px;
    padding: 0px 10px;
    margin: 0px;
    line-height: 28px;
}

.tab-table-container {
    margin: 16px;
    border-radius: 10px;
}

.data-container {
    padding: 0px 10px;
}

.addTag {
    font-size: 12px;
    margin: 0px;
    color: #0077be;
    padding: 0px 2px;
}

.tag-container {
    padding: 12px 0px 0px 0px !important;
    border: none !important;
    margin: 20px 10px 0px 20px !important;
}

.accor-margin {
    /* margin-top: 16px; */
}

.accor-title {
    text-align: left;
    margin: 0px !important;
}

.detailBg.listdetails .content .card.card-content+.card.card-table {
    flex: 1;
    margin-bottom: 12px !important;
}

.detailBg.listdetails .content {
    min-height: calc(100vh - 108px);
    max-height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-bottom: 24px;
}

.detailBg.listdetails>.breadcrumb-wrapper {
    flex-shrink: 0;
}

.tabview-list {
    background: #fff;
}

.detailBg.listdetails {
    padding: 0;
    min-height: initial;
    max-height: initial;
    overflow: hidden;
}

.addTag svg {
    font-size: 9px;
}

.card.card-content.card-content-head .card-body {
    padding: 0;
}

.card.card-content.card-content-head .card-body .d-flex.head-details {
    padding: 24px 16px;
}

.card.card-content.card-content-head .card-body .tag-container {
    background: #f8fafb;
    padding: 7.5px 16px !important;
    margin: 0 !important;
    border-radius: 0 0 8px 8px;
}

.card.card-content.card-content-head .card-body .tag-container .item {
    display: flex;
    align-items: center;
}

.card.card-content.card-content-head .card-body .d-flex.head-details .column-flex.flexDisplay>img {
    width: 40px;
    height: 40px;
    overflow: hidden;
}

.card.card-content.card-content-head .card-body .d-flex.head-details .data-container {
    display: flex;
    flex-direction: column;
    padding: 0 8px;
}

.card.card-content.card-content-head .card-body .d-flex.head-details .data-container .common-text {
    line-height: 15px;
}

.card.card-content.card-content-head .card-body .d-flex.head-details .data-container .common-text .icon-padding {
    padding: 0 4px;
    position: relative;
    top: 2px;
    cursor: pointer;
}

.card.card-content.card-content-head .card-body .tag-container .item.tags {
    border-width: 1px;
    border-style: solid;
    padding: 2px 4px;
    border-radius: 4px;
    margin-right: 4px;
    margin-left: 0px;
}

.card.card-content.card-content-head .card-body .tag-container .item.tags p {
    margin: 0;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
}

.arrow-steps .step {
    font-size: 14px;
    text-align: center;
    color: #666;
    cursor: pointer;
    margin: 0 3px;
    padding: 1px 10px 2px 30px;
    min-width: 209px;
    flex: 0 0 auto;
    position: relative;
    background-color: #d9e3f7;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 0.2s ease;
    border: none;
}

.arrow-steps .step select {
    background: transparent;
    border: none;
    height: 20px;
    cursor: pointer;
    font-size: 12px;
}

.arrow-steps .step:after,
.arrow-steps .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -15px;
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 15px solid #d9e3f7;
    z-index: 2;
    transition: border-color 0.2s ease;
}

.arrow-steps .step:before {
    right: auto;
    left: 0;
    border-left: 15px solid;
    z-index: 0;
    border-left-color: #fff;
}

.arrow-steps .step:first-child:before {
    border: none;
}

.arrow-steps .step:first-child {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    margin-left: 0;
}

.arrow-steps .step span {
    position: relative;
}

.arrow-steps .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
}

.arrow-steps .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
}

.arrow-steps .step.current {
    color: #fff;
    /* background-color: #23468c; */
}

.arrow-steps .step.current:after {
    border-left: 15px solid;
}

.arrow-steps .step p {
    font-weight: normal;
    font-size: 12px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    margin: 0;
}

.arrow-steps .step.current p {
    color: #fff;
}

.card.card-status-block .block-container .flexDisplay {
    align-items: center;
    justify-content: space-between;
}

.card.card-status-block .block-container .progress-wrapper {
    width: 100%;
}

.card.card-status-block .block-container .progress-wrapper .arrow-steps {
    display: flex;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    padding-bottom: 4px;
}

.card.card-status-block .block-container .flexDisplay p.item {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 6px;
}

.card.card-status-block .card-body {
    padding: 20px 16px !important;
}

.arrow-steps .step>a {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    display: inherit;
}

.content-wrapper .content .card.card-accordion {
    margin: 0 16px 12px;
    width: auto;
}

.fieldset.dataset .input-group .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin: 0;
    text-align: left;
}

.group-listing .left-block {
    flex: unset;
    width: 330px;
    max-width: 100%;
}

.group-listing .right-block {
    flex: unset;
    width: calc(100% - 330px);
    max-width: 100%;
}

.group-listing .left-block {
    flex: unset;
    width: 330px;
    max-width: 100%;
}

.group-listing .right-block {
    flex: unset;
    width: calc(100% - 330px);
    max-width: 100%;
}

.tasks-list {
    padding: 0 16px;
}

.tasks-list .task-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 6px 0;
    box-shadow: inset 0px -0.5px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.tasks-list .task-item .l-block {
    flex: 1;
    padding-right: 30px;
    text-align: left;
}

.tasks-list .task-item .r-block {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
    padding-right: 8px;
}

.tasks-list .task-item .link {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
}

.tasks-list .task-item .text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #444444;
    margin: 0;
}

.tasks-list .task-item .r-block .date-time {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.tasks-list .task-item .r-block .date-time .item {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #555555;
    margin-left: 8px;
}

.tasks-list .task-item .r-block .date-time .item>span {
    margin-right: 4px;
    position: relative;
    top: 1px;
}

.tasks-list .task-item .r-block .attributes {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.tasks-list .task-item .r-block .attributes .item {
    padding-left: 8px;
    margin-left: 8px;
    position: relative;
}

.tasks-list .task-item .r-block .attributes .item .sub {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin-right: 8px;
}

.tasks-list .task-item .r-block .attributes .item .text {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #222222;
}

.tasks-list .task-item .r-block .attributes .item:before {
    content: "";
    position: absolute;
    left: 0;
    top: 25%;
    width: 1px;
    height: 50%;
    background: #999999;
}

.tasks-list .task-item .r-block .attributes .item:first-child:before {
    background: transparent;
}

.detailBg.inner-listdetails .card-details-section {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 258px) !important;
    min-height: calc(100vh - 258px) !important;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 0 !important;
    margin: 0 16px !important;
}

.card.card-accordion .accordion .accordion-item .accordion-body>.row.border-one {
    border-bottom: 1px solid #EDEEF1;
    margin-bottom: 0;
    padding-bottom: 8px;
}

.detailBg.inner-listdetails .card-details-section .accordion-body {
    max-height: initial !important;
}

.card.card-accordion .accordion .accordion-item .accordion-body>.row.border-one:last-child {
    /* border: none;
    margin-bottom: 0; */
}

.card.card-accordion .accordion .accordion-item .accordion-header.twocolumnblock {
    display: flex;
    align-items: center;
}

.card.card-accordion .accordion .accordion-item .accordion-header.twocolumnblock .accordion-button {
    flex: 1;
    padding-right: 30px;
}

.card.card-accordion .accordion .accordion-item .accordion-header.twocolumnblock .right-container {
    display: flex;
    align-items: center;
    padding-right: 16px;
}

.seach-con.search-secondary {
    height: 24px;
    margin: 0 0px 0 8px;
}

.seach-con.search-secondary fieldset.input-group {}

.seach-con.search-secondary fieldset.input-group input.form-control {
    height: 24px !important;
    line-height: 24px !important;
    min-height: 24px !important;
    color: #888888;
    font-weight: normal;
    font-size: 12px;
}

.card.card-accordion .accordion .accordion-item .accordion-header.twocolumnblock .right-container .item {}

.card.card-accordion .accordion .accordion-item .accordion-header.twocolumnblock .right-container .item .btn {
    margin-left: 8px;
}

.seach-con.search-secondary fieldset.input-group input.form-control::placeholder {
    font-size: 12px;
    line-height: 24px;
}

.card.card-accordion .accordion .accordion-item .accordion-header.twocolumnblock .right-container .showmore {
    width: 16px;
    height: 16px;
    margin-left: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card.card-accordion .accordion .accordion-item .accordion-header.twocolumnblock .right-container .showmore svg {
    font-size: 16px;
}

.tabview-list {
    padding-right: 76px;
}

.tabview-list .usertab {
    flex: 1;
    max-width: 185px;
    left: 0 !important;
    right: 0 !important;
}

.tabview-list .usertab .tab-title {
    width: 165px;
}

.detailBg.inner-listdetails {
    padding-right: 60px;
}

.detailBg.inner-listdetails .card-details-section>.card {
    margin: 0 0 12px !important;
}

.accordion-collapse.table-block .card.card-table.tab-table-container .toolbar_listing {
    margin: 0;
}

.card.card-table .card-body .table-section .toolbar_listing .toolbar-container .toolbar-serchblock .seach-con {
    margin-right: 8px;
    margin-bottom: 8px;
}

.accordion-collapse.table-block .accordion-body {
    padding: 0 !important;
}

.accordion-collapse.table-block .accordion-body>.row {
    margin: 0 -16px !important;
}

.arrow-steps .step.current select {
    color: #fff;
    text-align: center;
}

.arrow-steps .step select:focus-visible {
    border: none;
    outline: none;
}

.columnlayout .content {
    flex-direction: row !important;
    align-items: stretch;
    padding: 0 0 24px 16px;
}

.columnlayout .content .left-block {
    width: 275px;
    max-width: 20%;
    min-height: calc(100vh - 132px);
    max-height: calc(100vh - 132px);
    background: #FFFFFF;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.columnlayout .content .right-block {
    flex: 1;
    min-height: calc(100vh - 132px);
    max-height: calc(100vh - 132px);
    overflow-y: auto;
}

.columnlayout .content .left-block .left-head {
    flex-shrink: 0;
}

.columnlayout .content .left-block .left-head .block-title p.tab-sub-header {
    padding: 10px 16px;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #222222;
    border-bottom: 1px solid #EBEBEB;
    background: #F8FAFB;
}

.columnlayout .content .left-block .left-head .seach-con {
    margin: 10px 16px 0;
}

.columnlayout .content .left-block ul.list-block {
    padding: 12px 16px;
    margin: 0;
    overflow-y: auto;
    position: relative;
}

.columnlayout .content .left-block ul.list-block li {
    list-style: none;
    margin-bottom: 4px;
    border-radius: 4px;
}

.columnlayout .content .left-block ul.list-block li a {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 4px;
    text-decoration: none;
}

.columnlayout .content .left-block ul.list-block li a .text {
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #222222;
}

.columnlayout .content .left-block ul.list-block li a .count {
    font-weight: normal;
    font-size: 12.5px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-align: right;
    color: #222222;
}

.columnlayout .content .right-block .right-head {
    padding: 0 16px;
}

.columnlayout .content .right-block .right-head p.sub-header {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #222222;
    margin-bottom: 14px;
}

.columnlayout .content .right-block .right-head .card.card-accordion:last-child {
    margin-bottom: 0px;
}

.columnlayout .content .left-block .left-head .block-title>.item {
    margin: 12px 16px 0;
}

.columnlayout .content.conversation-layout .right-block {
    overflow: hidden;
    padding: 0 16px;
}

.conversation-detail {
    background: #FFFFFF;
    border-radius: 8px;
    min-height: calc(100vh - 132px);
    max-height: calc(100vh - 132px);
    /* overflow-y: auto; */
    display: flex;
    align-items: stretch;
}

.conversation-detail .conversation-list {
    width: 400px;
    max-width: 33%;
}

.conversation-detail .conversation-content {
    flex: 1;
}

.conversation-detail .conversation-list {
    width: 400px;
    max-width: 33%;
    flex: 0 0 400px;
}

.conversation-detail .conversation-content {
    flex: 1;
    width: calc(100% - 400px);
}

.conversation-detail .conversation-list .conversation-wrapper {
    min-height: calc(100vh - 132px);
    max-height: calc(100vh - 132px);
    display: flex;
    flex-direction: column;
    border-right: 1px solid #EDEEF1;
}

.conversation-detail .conversation-list .conversation-wrapper .conversation-head .title-block {
    display: flex;
    align-items: center;
    padding: 16px;
    background: #F8FAFB;
    border-radius: 8px 0 0 0;
}

.conversation-detail .conversation-list .conversation-wrapper .conversation-head .title-content {
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
}

.conversation-detail .conversation-list .conversation-wrapper .conversation-head .title-block .seach-con {
    flex: 1;
    margin-right: 10px;
}

.conversation-detail .conversation-list .conversation-wrapper .conversation-head .title-content .text {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #222222;
    margin: 0;
}

.conversation-detail .conversation-list .conversation-wrapper .conversation-head .title-content .sub {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-align: right;
    color: #555555;
}

.conversation-detail .conversation-content .conversation-wrapper {
    min-height: calc(100vh - 132px);
    max-height: calc(100vh - 132px);
    display: flex;
    flex-direction: column;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid #EDEEF1;
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
    min-height: 53px;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content .text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin: 0;
    text-overflow: ellipsis;
    /* background: red; */
    white-space: nowrap;
    overflow: hidden;
}

.shortenText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.min-table-height {
    min-height: 642px;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content .right-container {}

.conversation-wrapper ul.list-block {
    margin: 0;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.conversation-detail .conversation-list .conversation-wrapper .conversation-head {
    flex-shrink: 0;
}

.conversation-wrapper ul.list-block li {
    background: #FFFFFF;
    padding: 12px 16px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    border-bottom: 1px solid #EDEEF1;
    cursor: pointer;
}

.conversation-wrapper ul.list-block li .leftside {
    flex: 1;
    padding: 0 12px 0 14px;
    position: relative;
    text-align: left;
}

.conversation-wrapper ul.list-block li .rightside {
    display: flex;
    flex-direction: column;
}

.conversation-wrapper ul.list-block li .title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 4px;
}

.conversation-wrapper ul.list-block li .text.bold {
    font-weight: 600;
}

.conversation-wrapper ul.list-block li .text {
    font-size: 12px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 4px;
    font-weight: normal;
}

.conversation-wrapper ul.list-block li .tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.conversation-wrapper ul.list-block li .tags .tag {
    font-weight: normal;
    font-size: 10.5px;
    line-height: 14px;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    margin-right: 8px;
    padding: 2px 4px;
    display: inline-block;
}

.conversation-wrapper ul.list-block li .rightside .text {
    opacity: 0.8;
}

.conversation-wrapper ul.list-block li .rightside .attachment {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.conversation-wrapper ul.list-block li .leftside .unread {
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    left: 0;
    top: 6px;
}

.conversation-wrapper ul.list-block li.active .title,
.conversation-wrapper ul.list-block li.active .text {
    color: #fff;
}

.conversation-wrapper ul.list-block li.active .tags .tag {
    color: #ffffff !important;
    background: transparent !important;
    border-color: #ffffff !important;
}


/* .conversation-wrapper ul.list-block li.active .rightside .attachment svg path {fill: #fff;} */

.conversation-content .conversation-wrapper .conversation-head {
    flex-shrink: 0;
}

.conversation-content .conversation-wrapper .list-block {
    position: relative;
    overflow-y: auto;
    overflow-x: hidden;
    text-align: left;
    padding: 8px 24px;
    flex: 1;
}

.conversation-content .conversation-wrapper .list-block .item {
    padding: 16px 0;
    border-bottom: 1px solid #EDEEF1;
}

.conversation-content .conversation-wrapper .list-block .item .flex-row {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.conversation-content .conversation-wrapper .list-block .item .name {
    flex: 1;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin: 0;
}

.conversation-content .conversation-wrapper .list-block .item .right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.conversation-content .conversation-wrapper .list-block .item .right-container .link {
    display: inline-block;
    line-height: 18px;
    width: 16px;
    margin: 0 6px;
}

.conversation-content .conversation-wrapper .list-block .item .right-container .date {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    color: #222222;
    margin: 0 6px;
}

.conversation-content .conversation-wrapper .list-block .item .email {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #777777;
    margin: 0;
    margin-right: 8px;
    display: inline-block;
}

.conversation-content .conversation-wrapper .list-block .item .tags .tag {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin-right: 8px;
    padding: 2px 4px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
}

.conversation-content .conversation-wrapper .list-block .item .text,
.conversation-content .conversation-wrapper .list-block .item .text * {
    font-weight: normal;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #222222;
    margin-bottom: 0;
    word-break: break-word;
    background: transparent;
}

.conversation-content .conversation-wrapper .list-block .item .flex-row .text.l-height *:last-child {
    margin: 0 !important;
}

.conversation-content .conversation-wrapper .list-block .item .name>.text {
    font-weight: 600;
    margin-right: 8px;
    display: inline-block;
}

.conversation-content .conversation-wrapper .list-block .item .add-list {
    margin-right: 27px;
    line-height: 19px;
}

.conversation-content .conversation-wrapper .list-block .item .add-list .sub {
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin-right: 0;
    position: relative;
    padding-right: 3px;
}

.conversation-content .conversation-wrapper .list-block .item .add-list .sub:after {
    /* content: " , ";
    position: relative;
    width: 6px;
    display: inline-block;
    margin-left: 3px; */
}

.conversation-content .conversation-wrapper .list-block .item .add-list .sub:first-child:after,
.conversation-content .conversation-wrapper .list-block .item .add-list .sub:last-child:after {
    /* content: ""; */
}

.conversation-content .conversation-wrapper .list-block .item .flex-column {
    display: flex;
    margin-bottom: 12px;
}

.conversation-content .conversation-wrapper .list-block .item .sub-item .mail-content .sub-text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 12px;
}

.conversation-content .conversation-wrapper .list-block .item .sub-item .mail-content .sub-text span {
    margin-right: 10px;
    width: 60px;
    display: inline-block;
}

.conversation-content .conversation-wrapper .list-block .item .attachment-list .list-item {
    padding: 2px 4px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;
}

.conversation-content .conversation-wrapper .list-block .item .flex-row+.flex-column {
    margin-top: 16px;
}

.conversation-content .conversation-wrapper .list-block .item .attachment-list .list-item .link {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    margin: 0;
}

.conversation-content .conversation-wrapper .list-block .item .right-container .link:last-child {
    margin-right: 0;
}

.conversation-content .conversation-wrapper .list-block .item .flex-row:last-child {
    margin-bottom: 0;
}

.conversation-content .conversation-wrapper .list-block .item .flex-column:last-child {
    margin-bottom: 0;
}

.conversation-content .conversation-wrapper .list-block .item .flex-column .text.bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #333333;
    margin-bottom: 12px;
}

.text.l-height {
    line-height: 30px !important;
    margin-bottom: 16px !important;
    margin-top: 12px;
}

.conversation-content .conversation-wrapper .list-block .item .flex-row .text.l-height {
    margin-bottom: 0 !important;
}

.conversation-content .conversation-wrapper .list-block .item .sub-item .text {
    margin-bottom: 12px;
}

.conversation-content .conversation-wrapper .list-block .item .flex-column a.link {
    margin-bottom: 12px;
}

.conversation-content .conversation-wrapper .list-block .item .sub-item .mail-content .sub-text:last-child {}

.conversation-content .conversation-wrapper .list-block .item:last-child {
    border-bottom: none;
}

.conversation-content .conversation-wrapper .list-block .item .sub-item .mail-content {
    position: relative;
    padding-left: 20px;
}

.conversation-content .conversation-wrapper .list-block .item .sub-item .mail-content:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1px;
    height: 100%;
    background: #777;
}

.conversation-content .conversation-wrapper .list-block .item .attachment-list .list-item .icon {
    margin-right: 5px;
}

.columnlayout .content.conversation-layout .left-block {
    width: 240px;
    max-width: 16%;
}

.content.conversation-layout .left-block .mail-list {
    flex: 1;
    min-height: 30%;
    max-height: 30%;
    overflow-y: auto;
    padding-bottom: 8px;
    border-bottom: 1px solid #B6B6B6;
    margin: 0 16px;
}

.content.conversation-layout .left-block .mail-list1 {
    flex: 1;
    min-height: calc(70% - 88px);
    max-height: calc(70% - 88px);
    display: flex;
    flex-direction: column;
    padding: 8px 16px 0;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper {
    box-shadow: none;
    margin: 0;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper .accordion-header {
    min-height: initial !important;
    width: 100%;
    padding: 8px 4px;
    margin-bottom: 0 !important;
    background: transparent !important;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper .accordion-header p {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    text-align: left;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper .accordion-header svg {
    color: #222;
    width: 20px;
    height: 20px;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper .accordion-header .MuiAccordionSummary-content {
    margin: 0;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper .accordion-content {
    padding: 0;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper .accordion-content .list-block {
    padding: 4px 0 0;
    padding-right: 6px;
}

.content.conversation-layout .left-block .mail-list1 .mail-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px 4px;
}

.content.conversation-layout .left-block .mail-list1 .mail-head .text {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #222222;
    margin: 0;
}

.content.conversation-layout .left-block .mail-list1 .mail-head a {
    color: #222;
}

.content.conversation-layout .left-block .mail-list1 .mail-head .list-block {}

.content.conversation-layout .left-block .mail-list1 .list-block {
    padding: 8px 0;
    padding-right: 6px;
    height: 100%;
}

.content.conversation-layout .left-block .mail-list1 .list-block li a {}

.content.conversation-layout .left-block .mail-list1 .list-block li a .icon {
    margin-right: 5px;
}

.content.conversation-layout .left-block .mail-list1 .list-block li a .text {
    flex: 1;
    font-size: 12px;
    line-height: 20px;
    text-align: left;
}

.content.conversation-layout .left-block .mail-list1 .list-block li a .icon svg {
    position: relative;
    top: 0;
    color: #222;
    width: 13px;
    height: 13px;
}

.content.conversation-layout .left-block .mail-list1 .list-block li a .count {
    font-size: 12px;
}

.columnlayout .content.conversation-layout .left-block .left-head {
    height: 88px;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper:before {
    background: transparent;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper .accordion-content .list-block a .text,
.content.conversation-layout .left-block .mail-list .accordion-wrapper .accordion-content .list-block a .count {
    font-size: 12px;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper .accordion-content .list-block li:last-child {
    margin-bottom: 0;
}

.content.conversation-layout .left-block .mail-list .accordion-wrapper .accordion-content .list-block a .text {
    flex: 1;
    text-align: left;
    line-height: 20px;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block {
    width: 336px;
    max-width: initial;
}

.columnlayout .content .left-block ul.list-block .fieldset.checkbox-box>label.control-label {
    display: none;
}

.columnlayout .content .left-block ul.list-block .fieldset.checkbox-box label.control-label {
    margin: 0;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block .calendar-block {
    flex-shrink: 0;
    padding: 8px 16px;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block .timezone {
    /* flex-shrink: 0; */
    padding: 0px 16px 8px;
    /* border-bottom: 1px solid #B6B6B6; */
    text-align: left;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block .activities {
    /* flex-shrink: 0; */
    margin-top: 4px;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block .shrink-flex {
    position: relative;
    overflow-y: auto;
    border-top: 1px solid #B6B6B6;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block .timezone .item {
    padding: 4px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4px;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block .timezone .item .icon svg {
    width: 16px;
    height: 16px;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block .timezone .item .text {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #555555;
    flex: 1;
    margin-left: 4px;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block .timezone .item .date {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #555555;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block .activities .text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #222222;
    margin-bottom: 4px;
    padding: 8px 16px;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block ul.list-block {
    padding: 0 16px 8px;
    border-bottom: 1px solid #B6B6B6;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block ul.list-block .fieldset.checkbox-box {
    padding: 6px 4px;
    margin-bottom: 0;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block ul.list-block .fieldset.checkbox-box input {
    margin-right: 6px;
    position: relative;
    top: 1px;
    opacity: 1;
    width: 14px;
    height: 14px;
}

.calendar-block .rmdp-shadow {
    box-shadow: none;
    margin: 0 auto;
}

.calendar-block .rmdp-calendar {
    padding: 0;
    margin: 8px;
}

.calendar-block .rmdp-header {
    height: auto;
    line-height: normal;
    margin: 0 0 4px;
    padding: 4px 0;
}

.calendar-block .rmdp-header .rmdp-arrow-container {
    color: #333 !important;
    background: transparent;
    box-shadow: none;
    margin: 0 2px;
}

.calendar-block .rmdp-header .rmdp-header-values {}

.calendar-block .rmdp-header .rmdp-header-values span {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #666666;
}

.calendar-block .rmdp-header .rmdp-arrow-container i {
    border: solid #333;
    border-width: 0 2px 2px 0;
}

.calendar-block .rmdp-week-day {
    width: 40px;
    height: 26px;
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    color: #999999;
}

.calendar-block .rmdp-day-picker {
    padding: 0;
}

.calendar-block .rmdp-day {
    width: 40px;
    height: 30px;
    font-weight: normal;
    text-align: center;
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar-block .rmdp-day span {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    left: auto;
    right: auto;
    font-size: 12px;
    line-height: 20px;
    font-weight: normal;
    text-align: center;
}

.calendar-block .rmdp-week {
    margin-bottom: 5px;
}

.calendar-block .rmdp-day.rmdp-today span {
    color: #0077BE !important;
    background: transparent !important;
    border: 1px solid #0077BE !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: rgba(0, 119, 190, 0.06);
    color: #333;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
    background-color: #0074d9 !important;
    color: #fff !important;
}

.calendar-block .rmdp-week:last-child {
    margin-bottom: 0;
}

.calendar-block .rmdp-month-picker .rmdp-day span {
    width: auto;
    height: auto;
    padding: 10px !important;
}

.calendar-block .rmdp-year-picker .rmdp-day span {
    width: auto;
    height: auto;
    padding: 2px 4px;
}

.columnlayout .content.conversation-layout.calendar-layout .left-block .timezone .item:last-child {
    margin-bottom: 0;
}

.seach-con.universal-searchbox .form-control {
    font-weight: 500;
    font-size: 12px;
}

.seach-con.universal-searchbox .form-control::placeholder {
    font-weight: 500;
    font-size: 12px;
}

.content.conversation-layout.calendar-layout .conversation-content .conversation-head .left-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.content.conversation-layout.calendar-layout .conversation-content .conversation-head .right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.content.conversation-layout.calendar-layout .conversation-content .conversation-head .right-container .item.flex-btn {
    display: flex;
    align-items: center;
}

.content.conversation-layout.calendar-layout .conversation-content .conversation-head .right-container .item.flex-btn>button.btn.btn-primary {
    border-radius: 6px 0 0 6px;
}

.content.conversation-layout.calendar-layout .conversation-content .conversation-head .right-container .item.flex-btn .dropdown .btn {
    border-radius: 0px 6px 6px 0px;
    color: #fff;
    margin: 0;
    border-radius: 0 6px 6px 0;
    border-left: 1px solid #fff !important;
    font-size: 20px;
}

.content.conversation-layout.calendar-layout .conversation-content .conversation-head .left-container input.form-control {
    width: 350px;
}

.content.conversation-layout.calendar-layout .conversation-content .conversation-head .right-container>div {
    margin-left: 8px;
}

.calendar-layout .title-content {
    box-shadow: none !important;
    background: #F8FAFB !important;
    border-bottom: none !important;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block {}

.calendar-layout .list-block .item-head {
    position: relative;
    margin-bottom: 12px;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item {
    margin-bottom: 12px;
    background: #FFFFFF;
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 0 16px;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    min-height: 76px;
}

.calendar-layout .list-block .item-head .text {
    font-size: 12px;
    line-height: 20px;
    color: #222222;
    background: #fff;
    display: inline-block;
    margin-bottom: 0 !important;
    padding-right: 12px;
    z-index: 2;
    position: relative;
}

.calendar-layout .list-block .item-head .text span {
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
}

.calendar-layout .list-block .item-head:before {
    content: "";
    background: #D4D4D4;
    position: absolute;
    left: 0;
    top: 12px;
    width: 100%;
    height: 1px;
    z-index: 0;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .date {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #222222;
    padding-right: 20px;
    border-right: 1px solid #EDEEF1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .type {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 0;
    margin: 12px;
    min-width: 52px;
    border-radius: 4px;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .details {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .action {
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .details .attributes {
    display: flex;
    margin-top: 4px;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .type .text {
    font-weight: normal;
    font-size: 10.5px !important;
    line-height: 20px !important;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .details .text.bold {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
}

.attributes {}

.attributes .tags {}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .details .attributes .tags .tag {
    font-weight: normal;
    font-size: 10.5px;
    line-height: 20px;
    text-align: center;
    color: #222222;
    background: #EBEBEB;
    border-color: #EBEBEB;
    margin-right: 12px;
}

.attributes .item-sub {
    padding-right: 12px;
}

.attributes .item-sub .sub {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #555555;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .details .attributes .item-sub .text {
    font-weight: normal;
    font-size: 12px !important;
    line-height: 20px !important;
    color: #222222;
}

.text.red {
    color: #AB1B1B !important;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .details .attributes>div {
    position: relative;
    margin-right: 12px;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .details .attributes>div:before {
    content: "";
    position: absolute;
    right: 0;
    top: 12px;
    width: 4px;
    height: 4px;
    background: #000;
    border-radius: 100%;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .details .attributes>div:last-child:before {
    background: transparent;
}

.convert-modal.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 {
    width: 85% !important;
    max-width: 1326px !important;
    /* height: calc(100vh - 100px); */
    /* max-height: 728px; */
    min-height: calc(100vh - 241px);
}

.convert-modal.MuiModal-root .MuiDialog-container .MuiPaper-elevation24>div {
    overflow: hidden;
}

.convert-list .MuiAutocomplete-inputRoot input+div {
    background: #fafafa!important;
}

.convert-modal .MuiDialogActions-spacing .item {
    width: auto !important;
}

.convertpopup-content {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    height: 100%;
}

.convertpopup-content .convert-list {
    width: 380px;
    max-width: 30%;
    max-height: calc(100vh - 203px);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -8px;
    padding-right: 6px;
}

.convertpopup-content .convert-details {
    margin-left: 12px;
    padding-left: 12px;
    border-left: 1px solid #EDEEF1;
    flex: 1;
    max-height: calc(100vh - 203px);
    display: flex;
    flex-direction: column;
}

.convertpopup-content .convert-list .item {
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 8px;
}

.convertpopup-content .convert-list .item .flex-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
}

.convertpopup-content .convert-list .item .flex-details .link {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-decoration: none;
}

.convertpopup-content .convert-list .item .flex-details .name .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin-left: 8px;
}

.convertpopup-content .convert-list .item .flex-details .name .icon {
    display: inline-block;
    line-height: 14px;
    position: relative;
    top: 2px;
}

.convertpopup-content .convert-list .item.active .flex-details .link {
    color: #222222 !important;
}

.convertpopup-content .convert-list .item .flex-details .link svg {
    font-size: 10px;
    margin-right: 4px;
}

.convertpopup-content .convert-details .info-box {
    background: #F8F8F8;
    border-radius: 8px;
    width: 100%;
    display: block;
    margin-bottom: 16px;
}

.convertpopup-content .convert-details .info-box .text {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #555555;
    padding: 20px;
}

.convertpopup-content .convert-details .leadconvert-selectlist {
    display: flex;
    align-items: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: -16px;
    padding-right: 16px;
    /* max-height: 500px; */
    justify-content: flex-start;
}

.leadconvert-selectlist .left-list {
    flex: 1;
}

.leadconvert-selectlist .right-list {
    flex: 1;
}

.leadconvert-selectlist .list-head {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.leadconvert-selectlist .list-head p.text {
    font-weight: 600 !important;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #222222;
}

.leadconvert-selectlist .list-head .link {
    font-weight: normal;
    font-size: 10.5px;
    line-height: 20px;
    margin-left: 12px;
    cursor: pointer;
}

.leadconvert-selectlist .left-list .item {
    display: flex;
    margin-bottom: 12px;
}

.leadconvert-selectlist .left-list .item input[type="radio"] {
    position: relative;
    top: 2px;
}

.leadconvert-selectlist .left-list .item .checkbox-fieldset {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.leadconvert-selectlist .left-list .item .checkbox-fieldset label.radio-label {
    margin-left: 12px;
    flex: 1;
    width: 100%;
}

.leadconvert-selectlist .left-list .item .checkbox-fieldset label.control-label {
    min-height: 16px;
}

.leadconvert-selectlist .left-list .item .checkbox-fieldset p.textfield {
    height: 34px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #707070;
}

.leadconvert-selectlist .right-list .item {
    margin-bottom: 12px;
}

.leadconvert-selectlist .right-list .item .checkbox-fieldset {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
}

.leadconvert-selectlist .right-list .item .checkbox-fieldset input[type="radio"] {
    position: relative;
    top: 2px;
}

.leadconvert-selectlist .right-list .item .checkbox-fieldset label.radio-label {
    flex: 1;
    margin-left: 12px;
}

.convertpopup-content .convert-details .leadconvert-selectlist.empty-content {
    display: flex;
    justify-content: center;
    height: 100%;
    padding: 0;
}

.leadconvert-selectlist.empty-content .fullwidth .icon-box {
    width: 136px;
    height: 136px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
}

.leadconvert-selectlist.empty-content .fullwidth {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leadconvert-selectlist.empty-content .fullwidth p.text {
    font-weight: 600;
    font-size: 16.463px;
    line-height: 24px;
    text-align: center;
    color: #222222;
    margin-bottom: 30px;
}

.leadconvert-selectlist.empty-content .fullwidth p.sub {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: left;
    color: #555555;
    margin-bottom: 30px;
    width: 100%;
    max-width: 85%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.leadconvert-selectlist.empty-content .fullwidth p.sub .count {
    width: 28px;
    height: 28px;
    flex: 0 0 28px;
    border-radius: 100%;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 18px;
    border-width: 1px;
    border-style: solid;
}

.leadconvert-selectlist.leadconvert-createlist .list-head {
    flex: 0 0 100%;
    max-width: 100%;
}

.leadconvert-selectlist.leadconvert-createlist {
    flex-wrap: wrap;
}

.leadconvert-create {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: 0 -8px;
}

.leadconvert-create .fieldset {
    max-width: 100%;
    width: 50%;
    padding: 0 8px 12px;
}


/* media query */

@media (max-width: 2010px) {
    .leadconvert-create .fieldset {
        max-width: calc(100% / 2);
        width: calc(100% / 2);
    }
}

@media (max-width: 1625px) {
    .leadconvert-create .fieldset {
        max-width: calc(100% / 2);
        width: calc(100% / 2);
    }
}

@media (max-width: 1250px) {
    .leadconvert-create .fieldset {
        max-width: calc(100% / 2);
        width: calc(100% / 2);
    }
}

@media (max-width: 870px) {
    .leadconvert-create .fieldset {
        flex: 0 0 100%;
        max-width: 100%;
        width: 100%;
    }
}

@media(max-width: 1099px) {
    .leadconvert-create .fieldset {
        max-width: 100%;
        width: 100%;
    }
}

.common-dialog .MuiDialogActions-spacing .item {
    width: auto !important;
}

.common-dialog .details-view .fieldset.tag-box {
    padding-bottom: 8px;
}

.common-dialog .details-view .fieldset {
    padding-bottom: 12px;
}

.common-dialog .details-view .fieldset.tag-box .input-group .tag {
    margin-bottom: 4px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    padding: 1px 4px;
    margin-right: 8px;
}

.common-dialog .details-view .fieldset .input-group p.text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin: 0;
}

.content.conversation-layout.calendar-layout .conversation-content .conversation-head .right-container .icon svg {
    font-size: 11px;
    top: 0;
    margin-right: 3px;
}

.item.flex-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.item.flex-btn .dropdown .min_width {
    margin: 0 !important;
    border-radius: 0 6px 6px 0;
    padding: 0 5px !important;
}

.item.flex-btn>.btn {
    border-radius: 6px 0 0 6px;
}

.item.flex-btn .dropdown .min_width span.icon {
    line-height: 15px;
    font-size: 20px;
    position: relative;
    top: 0;
}

.item.flex-btn .dropdown .btn-secondary.min_width {
    border-left: 1px solid transparent !important;
}

.activity-drawer .activity-popup .activity-head .righ-container>div .btn {
    border-radius: 4px;
}

.forms-list.conversation-wrapper ul.list-block li .rightside {
    flex-direction: revert;
}

.activity-list.conversation-wrapper.forms-list ul.list-block li {
    align-items: flex-start;
}

.forms-list.conversation-wrapper ul.list-block li .rightside p.text {
    margin-bottom: 0;
    margin-right: 8px;
}

.conversation-wrapper.forms-list ul.list-block li .leftside {
    padding-left: 0;
}

.activity-drawer .activity-popup .activity-list.forms-detail .row-block p.text {
    flex: 1;
}

.activity-list.activity-detail .item .details .row-block .tags {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 8px;
    flex-wrap: wrap;
}

.activity-list.activity-detail .item .details .row-block .tags .tag {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    margin: 2px 8px 2px 0;
    padding: 2px 4px;
}

.dropdown>a {
    cursor: pointer;
}

.content-wrapper .content .card .card-body .column-flex .flex-btn.flexDisplay button.min_width {
    padding-left: 0 !important;
}

.detailBg.inner-listdetails.noright-wrap {
    padding-right: 0;
}

.content-wrapper .content .card .card-body .column-flex .flex-btn.flexDisplay .dropdown-menu:before {
    right: auto !important;
    left: 10px;
}

.content-wrapper .content .card .card-body .column-flex .flex-btn.flexDisplay .dropdown>a.textButton {
    padding: 0 !important;
    position: relative;
    top: -1px;
}

.content-wrapper .content .card .card-body .column-flex .flex-btn.flexDisplay .dropdown-menu {
    transform-origin: top left;
}

.conversation-detail .conversation-content .conversation-wrapper .card-details-section {
    min-height: calc(100vh - 144px);
    max-height: calc(100vh - 132px);
    overflow-y: auto;
}

.conversation-detail .conversation-content .conversation-wrapper .card-details-section .card-body {
    border: none !important;
}

.conversation-detail .conversation-content .conversation-wrapper .card-details-section h2.accordion-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.conversation-detail .conversation-content .conversation-wrapper .card-details-section h2.accordion-header .right-container {
    padding-right: 16px;
    display: flex;
    align-items: center;
}

.columnlayout .content.conversation-layout.profile-layout .left-block {
    width: 275px;
    max-width: 20%;
}

.columnlayout .content.conversation-layout.profile-layout .left-block .profile-head {
    flex-shrink: 0;
    padding: 16px;
}

.profile-layout .left-block .profile-list {
    flex-shrink: 0;
    padding: 0 16px;
    margin-bottom: 0;
}

.profile-layout .left-block .leftside-list {
    flex: 1;
    position: relative;
    overflow-y: auto;
    padding: 4px 16px;
}

.profile-layout .left-block .list-footer {
    flex-shrink: 0;
    padding: 14px;
    border-top: 1px solid #EDEEF1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.profile-layout .profile-head .flex-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 8px;
    border-radius: 8px;
}

.profile-layout .profile-head .flex-box .img-box {
    width: 40px;
    height: 40px;
    border-radius: 8px;
    overflow: hidden;
    margin-right: 8px;
}

.profile-layout .profile-head .flex-box .details {
    flex: 1;
    text-align: left;
}

.profile-layout .profile-head .flex-box .details .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin: 0;
}

.profile-layout .profile-head .flex-box .details .sub {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #222222;
    margin: 0;
}

.profile-layout .left-block .profile-list .head {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #888888;
    margin: 0;
    padding: 8px 4px;
}

.profile-layout .left-block .leftside-list ul {
    margin: 0;
    padding: 0;
}

.profile-layout .left-block .leftside-list ul .activelist {}

.profile-layout .left-block .leftside-list ul li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 4px;
    border-radius: 4px;
    cursor: pointer;
}

.profile-layout .left-block .leftside-list ul li a .text {
    font-weight: normal;
    font-size: 12.5px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #222222;
}

.profile-layout .left-block .leftside-list ul li a .icon {
    margin-right: 6px;
    position: relative;
    top: 1px;
}

.profile-layout .left-block .leftside-list ul li a {
    padding: 6px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.profile-layout .left-block .list-footer .icon-box {
    margin-right: 8px;
}

.profile-layout .left-block .list-footer .support {
    text-align: left;
}

.profile-layout .left-block .list-footer .support .text {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #222222;
    margin: 0;
}

.profile-layout .left-block .list-footer .support .link {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
}

.conversation-detail .conversation-content .conversation-wrapper .card-details-section .fieldset .input-group .show-icon {
    position: absolute;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 0.9rem;
    cursor: pointer;
}

.conversation-detail .conversation-content .conversation-wrapper .card-details-section .btn-block>.item {
    padding: 0 14px 12px;
}

.conversation-detail .conversation-content .conversation-wrapper .card-details-section .btn-block>.item button {
    margin: 0;
}

.conversation-detail .conversation-content .conversation-wrapper .card-details-section .text-box {
    padding: 0 14px 0;
}

.conversation-detail .conversation-content .conversation-wrapper .card-details-section .text-box p {
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    text-align: left;
    margin-bottom: 12px;
}

.import-content .text-block {
    margin-bottom: 16px;
}

.import-content .upload_dropzone {
    width: 100%;
    position: relative;
}

.import-content .checkbox-block {
    padding: 4px 0;
}

.import-content .checkbox-block .fieldset.checkbox-box>label.control-label {
    display: none;
}

.import-content .checkbox-block label.control-label {
    margin: 0 0 0 8px;
    font-weight: normal;
    font-size: 13px;
    color: #222222;
    cursor: pointer;
}

.import-content .checkbox-block input {
    position: relative;
    top: 2px;
    cursor: pointer;
}

.import-content .text-block .text {
    margin-bottom: 12px !important;
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 24px !important;
    color: #000000 !important;
}

.import-content .upload_dropzone input[type="file"] {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.import-content .upload_dropzone label.upload-label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 220px;
    background: #F8FAFB;
    border-radius: 4px;
    border: 1px dashed #B6B6B6;
    margin-bottom: 16px;
    cursor: pointer;
    position: relative;
    z-index: 2;
}

.import-content .upload_dropzone label.upload-label .text {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-bottom: 12px;
}

.import-content .upload_dropzone label.upload-label .sub {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #000000;
    margin-bottom: 12px;
}

.import-content .upload_dropzone label.upload-label .btn.btn-primary {
    width: auto !important;
}

.import-popup.MuiModal-root .MuiDialog-container .MuiDialog-paperScrollPaper {
    min-height: 530px;
}

.intruction-block {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.intruction-block .intruction-list .text-block ul {
    margin: 0;
    padding-left: 14px;
}

.intruction-block .intruction-list .text-block ul li .text {
    font-weight: normal !important;
    font-size: 13px !important;
    line-height: 24px !important;
    color: #222222 !important;
}

.intruction-block .intruction-list .text-block ul li {
    margin-bottom: 4px;
}

.import-popup .quickcreate-form {
    padding: 16px !important;
}

.import-popup form {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.item-box.cursor .type,
.item-box.cursor .text {
    cursor: pointer;
}

.activity-head.flex-left {
    justify-content: flex-start !important;
}

.convertpopup-content .convert-list .item .fieldblock .fieldset.selectbox-fieldset .form-control {
    background: #fff;
}

.activity-list.activity-detail .item .details .row-block .item {
    padding: 0;
    background: transparent;
    border-radius: 6px;
    box-shadow: none;
}

.card.card-accordion .accordion .accordion-item .accordion-body .row>.row-btnblock {
    flex: 0 0 100%;
    max-width: 100%;
    text-align: left;
    padding: 0 14px 14px;
    display: flex;
    align-items: center;
}

.card.card-accordion .accordion .accordion-item .accordion-body .row>.row-btnblock button {
    margin-left: 0;
    margin-right: 8px;
}

.card.card-accordion .accordion .accordion-item .accordion-body .row>.row-title h4.d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content .right-container svg {
    width: 14px;
}

@media(max-width:1290px) {
    .content.conversation-layout.calendar-layout .conversation-content .conversation-head .left-container input.form-control {
        width: 150px;
    }
}

.popup-stretch-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-stretch-block .empty-content {
    flex-direction: column;
}

.popup-stretch-block .empty-content .img-box {
    margin-bottom: 8px;
}

.popup-stretch-block .empty-content .text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #888888;
}

.list-block.stretch_display {
    flex: 1;
}

.list-block.stretch_display .noRecords-block {
    text-align: center;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .action.dropdown>.dropdown-menu {
    top: unset !important;
    bottom: unset !important;
    right: 30px !important;
    transform-origin: right;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .action.dropdown>.dropdown-menu:before {
    right: -10px;
    top: calc(50% - 10px);
    border-left: 10px solid #FFE;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: none;
}

.card-details-section.nomargin-zone .card.card-accordion {
    width: auto !important;
    margin: 0 16px 12px !important;
}

.card-details-section.nomargin-zone .card.card-table.tab-table-container .table-section {
    margin-top: 0;
}

.card-details-section.search-result-area.stretch_display {
    padding: 0;
    background: #FFFFFF;
    border-radius: 8px;
    margin: 0 16px;
    min-height: calc(100vh - 182px);
    max-height: calc(100vh - 182px);
}

.card-details-section.search-result-area.stretch_display .fullwidth {
    margin: 0 !important;
}

.detailBg.listdetails .under-contruction .sub {
    /* color: #fff; */
}

.header ul.nav-right .notification.timezone>div>a {
    position: relative;
    top: 1px;
}

.activity-twocolumn.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 .activity-dialogcontent-loader {
    min-height: 480px;
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.activity-twocolumn.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 .activity-dialogcontent-loader>div {
    display: flex;
    align-items: center;
    justify-content: center;
}

.activity-list.activity-detail .item .details .row-block .attribute .dropdown>a.disabled {
    pointer-events: none;
}

.calendar-layout .conversation-content .conversation-wrapper .list-block .item .details .attributes>div:last-child:before {
    background: transparent;
}

.attributes.no_divider>div:before {
    background: transparent;
}

.rmdp-ym .rmdp-day span {
    border-radius: 4px;
    padding: 2px 0;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
    background-color: rgba(0, 119, 190, 0.06);
    color: #333;
}

.chip_blocks .input-group .form-control-block {
    border: 1px solid #edeef1;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: normal;
    font-size: 13px;
    color: #222222;
    padding: 0px 8px;
    transition: all 0.3s ease;
    background-image: none;
    min-height: 34px;
    display: flex;
    align-items: center;
    border-color: #EDEEF1 !important;
    background-color: #FAFAFA !important;
}

.chip_blocks .input-group .form-control-block .tags {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.chip_blocks .input-group .form-control-block .tags .tag {
    position: relative;
    font-size: 11px;
    height: 24px;
    line-height: 24px;
    padding: 0px 2px;
    border: 1px solid;
    border-radius: 4px;
    margin: 2px 8px 2px 0;
}

.universal-searchbox .selectbox li>span {
    display: none;
}

.card.card-accordion .accordion .accordion-item .accordion-body .fieldset_bottom_btn {
    padding: 0 14px 14px;
}

.fieldset_bottom_btn button.btn {
    margin-top: 25px;
    margin-left: 0 !important;
}

button.btn.btn-primary.border_primary {
    border-radius: 6px !important;
}

ul#select-pagesize-listbox.MuiAutocomplete-listbox {
    padding: 3px 0 !important;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%) !important;
}

ul#select-pagesize-listbox li.MuiAutocomplete-option {
    padding: 3px 12px !important;
}

.convert-list .MuiAutocomplete-inputRoot input+span {
    right: 10px !important;
    position: absolute !important;
    background: #fff !important;
    z-index: 1 !important;
}

.dropdown.convert_dropdown .dropdown-menu {
    width: 300px !important;
}

.dropdown.convert_dropdown .dropdown-menu .dropdown-item {
    padding: 2px 0.8rem;
}

.dropdown.convert_dropdown .dropdown-menu .dropdown-item span {
    margin-left: 6px;
    white-space: break-spaces;
    display: flex;
    flex: 1;
}
.dropdown.convert_dropdown .dropdown-menu a.dropdown-item {
    max-width: 100%;
}

.content.conversation-layout .left-block .mail-list1 .list-block .empty-stretch {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.content.conversation-layout .left-block .mail-list1 .list-block .empty-stretch>p {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #888888;
    margin: 0;
}

.conversation-wrapper ul.list-block .empty-stretch {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.conversation-wrapper ul.list-block .empty-stretch .fullwidth {
    width: 100%;
    margin: 0 !important;
}

.conversation-wrapper ul.list-block .empty-stretch p.text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #888888;
    margin: 12px 0 0;
}

.conversation-content .conversation-wrapper .list-block.list-block_stretch {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #888888;
}

.fieldset .input-group.muiselect .form-control #demo-multiple-chip {
    max-height: 140px;
    overflow-y: auto;
    margin-right: -8px !important;
    padding-right: 8px !important;
}

.convertpopup-content .convert-details .list-head {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.convertpopup-content .convert-details .list-head p.text {
    font-weight: 600 !important;
}

.convertpopup-content .convert-details .item_flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #EDEEF1;
    margin-bottom: 12px;
}

.convertpopup-content .convert-details .item_flex>.list-head {
    flex: 1;
    max-width: 50%;
}

.convertpopup-content .convert-details .item_flex .list-head .link {
    font-weight: normal;
    font-size: 10.5px;
    line-height: 20px;
    margin-left: 6px;
    cursor: pointer;
    position: relative;
    top: 2px;
}

.MuiAutocomplete-root .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline {
    border: none;
}

.convertpopup-content .convert-details .item_flex>.list-head p.text {
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin: 0;
}

.leadconvert-selectlist .left-list .item .item_lflex {
    flex: 1;
    max-width: 50%;
}

.leadconvert-selectlist .left-list .item .item_rflex {
    flex: 1;
    max-width: 50%;
}

.leadconvert-selectlist .left-list .item {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    border-bottom: 1px solid #EDEEF1;
    padding-bottom: 6px;
}

.leadconvert-selectlist .left-list .item:last-child {
    border-bottom: none;
    padding-bottom: 0;
}

.activity-list.timeline-list .timeline-item .item-box p.text>div>b {
    font-weight: 500;
    /* font-style: italic; */
}

@media(max-width:1200px) {
    .convertpopup-content .convert-details {
        margin-left: 12px;
        padding-left: 12px;
        border-left: 1px solid #EDEEF1;
        flex: 1 1;
        /* max-height: calc(100vh - 239px); */
        /* overflow-y: auto; */
        overflow-x: hidden;
    }
}

.manage_list .fieldset.selectbox-fieldset .form-control {
    min-height: auto !important;
    height: auto !important;
    padding: 0 !important;
    border: none !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #0077BE;
    padding-right: 0 !important;
    cursor: pointer;
}

.manage_list .fieldset.selectbox-fieldset .form-control .MuiSelect-icon {
    right: 0 !important;
    top: calc(50% - 13px) !important;
    color: #0077BE;
}

.manage_list .fieldset.selectbox-fieldset .form-control>div>span {
    color: #0077BE !important;
}

.manage_list .fieldset.selectbox-fieldset .form-control>.MuiSelect-select.MuiSelect-outlined {
    padding-right: 24px !important;
}

.manage_list {
    min-width: 95px;
}

.convert-list .MuiAutocomplete-inputRoot input::placeholder {
    font-weight: normal;
    font-size: 13px;
    line-height: 34px;
}

.accordion-item.email_settingsitem .accordion-body {
    padding: 16px !important;
    border-bottom: 1px solid #EDEEF1;
    overflow-x: hidden;
}

.email_settingsitem .accordion-body .btn-block {
    margin-left: 6px;
}

.accordion-item.email_settingsitem .accordion-body>.card.card-table.tab-table-container {
    margin: 0 0 12px;
}

.fieldset.fieldset_textEditor {
    max-width: calc(100% / 2) !important;
    width: calc(100% / 2) !important;
    padding-bottom: 0 !important;
}

.RichTextEditor__root___2QXK- {
    border-radius: 4px;
    border: 1px solid #CACEDA;
}

.convert-modal.MuiModal-root .MuiDialog-container .MuiDialogContent-root {
    padding: 16px;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content>.right-container {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content>.right-container .item {
    margin-left: 8px;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content>.right-container .item .dropdown-block {
    line-height: 16px;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content>.right-container .item .dropdown svg {
    position: relative;
    top: 1px;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content>.right-container .item .btn.btn-tertiary svg {
    margin-right: 5px;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content>.right-container .item .btn.btn-tertiary .para {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-right: 4px;
}

.conversation-detail .conversation-content .conversation-wrapper .conversation-head .title-content>.right-container .item .btn.btn-tertiary .count {
    width: 20px;
    height: 20px;
    border-radius: 24px;
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.email-popup .mail-content .mailform-content .receiverBox input.form-control {
    padding-right: 30px;
}

.email-popup .mail-content .mailform-content .receiverBox textarea.form-control {
    line-height: 21px;
}

.activity-list.emaildetail_list.conversation-content .conversation-wrapper {
    height: 100%;
}

.activity-list.emaildetail_list.conversation-content .list-block .item .add-list {
    margin: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
}

.activity-list.emaildetail_list.conversation-content .list-block .item .add-list .sub {
    word-break: break-all;
    padding: 0;
}

.activity-list.emaildetail_list.conversation-content .list-block .item .add-list .sub:first-child {
    flex: 0 0 25px;
    padding-right: 3px;
}

.activity-list.emaildetail_list.conversation-content .list-block .item .right-container .date {
    margin-right: 0;
}

.dropdown-menu.dropdown_emailDetails .item_row .details>span {
    white-space: break-spaces;
    word-break: break-word;
}

.activity-list.emaildetail_list.conversation-content .item .flex-row .name {
    padding-right: 12px;
}

.activity-list.emaildetail_list.conversation-content .item .flex-row:first-child {
    align-items: flex-start;
}

.assign_selectmodal .quickcreate-form {}

.assign_selectmodal .quickcreate-form .form-control {
    margin: 0;
    font-weight: normal;
    font-size: 13px;
    line-height: 32px;
}

.assign_selectmodal .quickcreate-form .form-control input {
    padding: 0 8px;
    font-weight: normal;
    font-size: 13px;
    line-height: 32px;
}

.assign_selectmodal .quickcreate-form .form-control .MuiAutocomplete-inputRoot {
    line-height: 32px;
    min-height: 32px;
    background: #fff !important;
    padding-right: 40px !important;
}

.assign_selectmodal .quickcreate-form .form-control input::placeholder {
    font-size: 13px;
    line-height: 32px;
}

.assign_selectmodal .quickcreate-form .form-control button.MuiIconButton-root {
    position: relative;
    top: 0px;
}

.assign_selectmodal .quickcreate-form .form-control span.MuiCircularProgress-root {
    position: absolute;
    right: 10px;
    background: #fff;
    z-index: 2;
}

.assign_selectmodal .quickcreate-form .form-control .MuiAutocomplete-inputRoot .MuiChip-filled {
    height: 22px;
    width: auto;
    background: rgba(0, 119, 190, 0.06);
    /* Themecolors/Primary color */
    border: 1px solid #0077BE;
    box-sizing: border-box;
    border-radius: 4px;
}

.assign_selectmodal .quickcreate-form .form-control button.MuiAutocomplete-clearIndicator {
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 1;
    height: 100%;
}

.assign_selectmodal .quickcreate-form .form-control button.MuiAutocomplete-clearIndicator svg {
    width: 16px;
}

.assign_selectmodal .quickcreate-form .form-control .MuiAutocomplete-inputRoot .MuiChip-filled span.MuiChip-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #0077BE;
    padding: 0 12px 0 4px;
}

.assign_selectmodal .quickcreate-form .form-control .MuiAutocomplete-inputRoot .MuiChip-filled svg.MuiSvgIcon-root {
    margin-right: 4px;
    width: 16px;
}

.assign_selectmodal .quickcreate-form .form-control .MuiAutocomplete-inputRoot span.MuiAutocomplete-tag {
    height: 22px;
    width: auto;
    background: rgba(0, 119, 190, 0.06);
    border: 1px solid #0077BE;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #0077BE;
    margin: 0 3px;
    padding: 1px 4px;
}

.assign_selectmodal .quickcreate-form .MuiInputBase-formControl fieldset {
    top: 0;
}

.assign_selectmodal .quickcreate-form .MuiInputBase-formControl .MuiAutocomplete-endAdornment {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.fieldblock .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    display: none;
}

.MuiDialogContent-root .MuiAutocomplete-root .MuiFormControl-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment {
    display: none;
}

ul.MuiMenu-list li.email_expand_menu {
    max-width: 100%;
}
.card.card-accordion .accordion .accordion-item .accordion-body .fieldset.checkbox-box .input-group input {
    position: relative !important;
    opacity: 1;
    width: 14px;
}