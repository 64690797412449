.sidebar-expanded {
    position: fixed;
    left: 0;
    z-index: 9999;
    width: 60px;
    top: 0;
    max-height: 100vh;
    min-height: 100vh;
    /* background: #1d181a; */
    box-shadow: 4px 0px 12px rgba(0, 0, 0, 0.06);
    padding: 4px 0px 0;
    transition: all 0.2s ease;
    display: flex !important;
    flex-direction: column;
    overflow: hidden;
}

.sidebar-expanded .menu-collapsed {
    opacity: 0;
    transition: all 0.05s ease;
}

.bor-rad {
    border-radius: 4px!important;
}

.sidebar-expanded .submenu-arrow {
    opacity: 0;
    transition: all 0.05s ease;
}

.sidebar-expanded .list-group {
    position: relative;
    overflow-y: auto;
    margin: 1rem 0;
    padding: 0 10px;
    overflow-x: hidden;
}

.sidebar-expanded .list-group>a {
    position: relative;
    display: inline-block;
    color: #ffffff;
    /* background: rgba(255, 255, 255, 0.05); */
    border-radius: 4px;
    transition: all 0.2s ease;
    margin-bottom: 8px;
    text-decoration: none;
}

.sidebar-expanded .list-group>a .d-flex {
    position: relative;
    justify-content: flex-start !important;
    min-width: 32px;
    min-height: 32px;
    align-items: center;
    margin-bottom: 0px;
    padding: 0 12px;
}

.sidebar-expanded .list-group>a .d-flex .menu-collapsed {
    position: absolute;
    left: 38px;
    top: 5px;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    width: 170px;
    text-align: left;
}

.sidebar-expanded .list-group>a .d-flex .submenu-arrow {
    position: absolute;
    right: 0;
}

.sidebar-expanded .list-group .sidebar-submenu {
    flex-direction: column;
    padding: 0 4px;
    background: transparent;
    transition: all 0.2s ease;
}

.sidebar-expanded .list-group .sidebar-submenu a {
    position: relative;
    justify-content: flex-start !important;
    min-width: 32px;
    min-height: 32px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #ffffff;
    margin-bottom: 8px;
    padding: 0 9px;
    text-decoration: none;
}

.sidebar-expanded .list-group .sidebar-submenu a .menu-collapsed {
    opacity: 0;
    transition: all 0.2s ease;
    position: absolute;
    left: 38px;
    top: 7px;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #ffffff;
    width: 170px;
    text-align: left;
}

.sidebar-expanded .list-group>a.collapsed {
    background: transparent !important;
    transition: all 0.2s ease;
}

.sidebar-expanded .list-group .sidebar-submenu.show {
    /* background: rgba(255, 255, 255, 0.05); */
    border-radius: 0 0 4px 4px;
    transition: all 0.2s ease;
    display: flex;
    margin-bottom: 8px;
    margin-top: -8px;
}

.sidebar-expanded .list-group .sidebar-submenu a.active {
    border-radius: 4px;
    color: #ffffff;
}

.sidebar-expanded:hover,
.sidebar-expanded:focus {
    width: 250px;
    transition: all 0.2s ease;
}

.sidebar-expanded a.nav-brand {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    flex-shrink: 0;
    padding: 0 10px;
}

.sidebar-expanded a.nav-brand .title {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #ffffff;
    position: absolute;
    left: 61px;
}

.sidebar-expanded a.nav-brand img {
    max-width: 41px;
    transition: all 0.2s ease;
    border-radius: 8px;
}

.sidebar-expanded:hover .menu-collapsed,
.sidebar-expanded:focus .menu-collapsed {
    opacity: 1;
    transition: all 0.2s ease;
}

.sidebar-expanded:hover a.nav-brand img,
.sidebar-expanded:focus a.nav-brand img {
    transition: all 0.2s ease;
    /* border-radius: 50px; */
}

.sidebar-expanded:hover .list-group>a .d-flex .submenu-arrow,
.sidebar-expanded:focus .list-group>a .d-flex .submenu-arrow {
    opacity: 1;
    transition: all 0.2s ease;
}

.sidebar-expanded:hover .list-group>a .d-flex,
.sidebar-expanded:focus .list-group>a .d-flex {
    justify-content: flex-start !important;
    transition: all 0.2s ease;
    padding: 0 12px;
}

.sidebar-expanded:hover .list-group .sidebar-submenu a,
.sidebar-expanded:focus .list-group .sidebar-submenu a {
    transition: all 0.2s ease;
    justify-content: flex-start !important;
    padding: 0 12px;
}

.sidebar-expanded:hover .list-group .sidebar-submenu a .menu-collapsed,
.sidebar-expanded:focus .list-group .sidebar-submenu a .menu-collapsed {
    transition: all 0.2s ease;
    opacity: 1;
}

.sidebar-expanded .list-group .sidebar-submenu a.active .menu-collapsed {
    color: #fff;
}

.sidebar-expanded .search-icon {
    border: 1px solid #909090;
    border-radius: 4px;
    margin-top: 16px;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #ffffff;
    padding: 0 12px;
    flex-shrink: 0;
    margin: 16px 10px 0;
}

.sidebar-expanded .search-box {
    margin-top: 1rem;
    display: none;
    flex-shrink: 0;
    padding: 0 10px;
}

.sidebar-expanded .search-icon>span.icon {
    position: relative;
    top: 1px;
    font-size: 14px;
}

.sidebar-expanded .search-box fieldset.input-group {
    border: 1px solid #909090;
    box-sizing: border-box;
    border-radius: 4px;
    height: 32px;
}

.sidebar-expanded .search-box fieldset.input-group .btn {
    padding: 0;
    margin: 0;
    min-width: 32px;
    min-height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    padding: 0 12px;
}

.sidebar-expanded .search-box fieldset.input-group input.form-control {
    background-image: none;
    font-weight: normal;
    font-size: 12px;
    line-height: 32px;
    color: #909090;
    height: 30px;
    padding: 0;
}

.sidebar-expanded:hover .search-icon,
.sidebar-expanded:focus .search-icon {
    display: none;
    transition: all 0.2s ease;
}

.sidebar-expanded .search-box fieldset.input-group input.form-control::placeholder {
    font-size: 12px;
    line-height: 32px;
}

.sidebar-expanded:hover .search-box,
.sidebar-expanded:focus .search-box {
    display: block;
}

.sidebar-expanded .list-group>a.active span.icon {
    color: #fff;
    transition: all 0.2s ease;
}

.sidebar-expanded .list-group>a.active .menu-collapsed {
    color: #fff;
    transition: all 0.2s ease;
}

.sidebar-expanded .list-group>a.active .submenu-arrow {
    color: #fff;
    transition: all 0.2s ease;
}

.sidebar-expanded .list-group>a.active .submenu-arrow svg {
    transform: rotate(180deg);
    transition: all 0.2s ease;
}

.sidebar-expanded .list-group>a .submenu-arrow svg {
    transition: all 0.2s ease;
    transform: rotate(180deg);
}

.sidebar-expanded .list-group>a.collapsed .d-flex .submenu-arrow svg {
    transition: all 0.2s ease;
    transform: rotate(0deg);
}

.sidebar-expanded .list-group>a span.icon {
    transition: all 0.2s ease;
    font-size: 12px;
    font-weight: 600;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.0);
    border-radius: 0px;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 100px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .00);
    background-color: #c1c1c1;
}

.inner-navmenu .navmenu-list ul {
    position: fixed;
    right: 0;
    top: 50px;
    height: calc(100vh - 74px);
    max-height: calc(100vh - 74px);
    background: #FFFFFF;
    box-shadow: 0px 8px 48px rgba(47, 47, 47, 0.07);
    margin: 0;
    padding: 14px 0;
    width: 60px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.inner-navmenu .navmenu-list ul li {
    list-style: none;
    display: block;
    margin: 0 auto 8px;
}

.inner-navmenu .navmenu-list ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
}

.inner-navmenu .navmenu-list ul li.linedivider {
    width: calc(100% - 28px);
    left: 14px;
    border-top: 1px solid #EDEEF1;
    height: 1px;
}
.sidebar-expanded a.nav-brand.nav-brand-footer {
    background: #111111;
    padding: 10px;
    border-top: 1px solid #484848;
}
.sidebar-expanded a.nav-brand .icon-bg {
    width: 41px;
    height: 41px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border-radius: 8px;
    border: 1px solid #fff; */
}

.sidebar-expanded a.nav-brand .icon-bg img {
    width: auto;
    max-width: initial !important;
}

.sidebar-expanded a.nav-brand  .title.menu-collapsed img {
    max-width: initial !important;
}