.MuiAutocomplete-root .MuiOutlinedInput-root {
    padding: 0px!important;
    background: #fafafa!important;
    border: 1px solid #00000042!important;
}
.MuiAutocomplete-popper * {
    font-size: 13px !important;
    /* color: #222222 !important; */
}
.MuiCheckbox-colorSecondary {
    color: #222222 !important;
}
.MuiCheckbox-colorSecondary.Mui-checked {
    color: #0077BE !important;
}
.MuiAutocomplete-popper > div > div {
    padding: 8px !important;
}

.MuiAutocomplete-popper > input[type="checkbox"]:checked + label.control-label:before {
    transition: all 0.2s ease;
    background: #0077BE;
}