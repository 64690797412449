.filter-popup {
    min-height: 100vh;
    max-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
}

.filter-popup .drawer-head {
    padding: 12px;
    border-bottom: 1px solid #EBEBEB;
    flex-shrink: 0;
}

.filter-popup .filterlist {
    padding: 12px;
    flex: 1;
    overflow-y: auto;
    position: relative;
    overflow-x: hidden;
}

.filter-popup .drawer-footer {
    padding: 12px;
    border-top: 1px solid #F5F5F5;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.filter-popup .drawer-footer > .item {
    margin-left: 12px;
}

.filter-popup .drawer-footer .text {
    color: #707070;
    flex: 1;
    text-align: center;
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    text-decoration: none;
}

.filter-popup .drawer-footer .text.disabled {
    color: #B6B6B6;
}

.filter-popup .drawer-head .drawer-title {
    padding-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-popup .drawer-head .filter-search.seach-con {
    margin: 0;
}

.filter-popup .drawer-head .drawer-title  h4 {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #222222;
    margin: 0;
}

.filter-popup .drawer-head .drawer-title .link {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
}
.filter-popup .drawer-head .filter-search.seach-con fieldset.input-group {
    min-height: 32px;
}

.filter-popup .drawer-head .filter-search.seach-con fieldset.input-group button.btn.no-margin {
    min-height: 32px;
}
.filter-popup .drawer-head .filter-search.seach-con fieldset.input-group input.form-control {
    margin-left: 10px !important;
}
.filter-popup .filterlist .filter-accordion {
    box-shadow: none !important;
    border-color: #EBEBEB;
    border-style: solid;
    border-width: 0px 0px 1px 0px;
    margin-bottom: 4px !important;
}
.filter-popup .filterlist .filter-accordion .accordion-header {
    min-height: 32px;
    width: 100%;
    padding: 6px 0;
    border-radius: 0 !important;
}

.filter-popup .filterlist .filter-accordion .accordion-content {
    padding: 4px 0 16px;
}

.filter-popup .filterlist .filter-accordion .accordion-header p {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    color: #222222;
}

.filter-popup .filterlist .filter-accordion .accordion-header > div {
    margin: 0;
}
.filter-popup .filterlist .checkbox-box {
    margin: 4px 0;
}

.filter-popup .filterlist .checkbox-box input[type="checkbox"], .filter-popup .filterlist .radio-box input[type="radio"] {
    position: absolute;
    opacity: 0;
}

.filter-popup .filterlist .checkbox-box input[type="checkbox"] + label.control-label:before,.filter-popup .filterlist .checkbox-box input[type="checkbox"] + label.control-label:after {content: "";transition: all 0.2s ease;}

.filter-popup .filterlist .checkbox-box label.control-label,.filter-popup .filterlist .radio-box label.control-label {
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #222222;
    margin: 0;
    padding-left: 20px;
    cursor: pointer;
}

.filter-popup .filterlist .checkbox-box input[type="checkbox"]:checked + label.control-label:before {
    transition: all 0.2s ease;
    background: #0077BE;
}

.filter-popup .filterlist .checkbox-box input[type="checkbox"]:checked + label.control-label:after {
    transition: all 0.2s ease;
    width: 4px;
    height: 7px;
    position: absolute;
    left: 5px;
    top: 7px;
    border-color: #fff;
    border-style: solid;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
}

.filter-popup .filterlist .checkbox-box input[type="checkbox"] + label.control-label:before {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 5px;
    border-radius: 4px;
    border: 1px solid #E0E2E7;
    transition: all 0.2s ease;
}
.filter-popup .filterlist .radio-box input[type="radio"] + label.control-label:before,.filter-popup .filterlist .radio-box input[type="radio"] + label.control-label:after {content: "";transition: all 0.2s ease;}
.filter-popup .filterlist .radio-box input[type="radio"]:checked + label.control-label:before {
    transition: all 0.2s ease;
    border: 1px solid #909090;
}

.filter-popup .filterlist .radio-box input[type="radio"]:checked + label.control-label:after {
    transition: all 0.2s ease;
    width: 8px;
    height: 8px;
    position: absolute;
    left: 3px;
    top: 8px;
    background: #0077BE;
    border-radius: 100%;
}

.filter-popup .filterlist .radio-box input[type="radio"] + label.control-label:before {
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0;
    top: 5px;
    border-radius: 4px;
    border: 1px solid #E0E2E7;
    transition: all 0.2s ease;
    border-radius: 100%;
}
.filter-popup .filterlist .filter-accordion:before {
    background: transparent;
}
.filter-popup .filterlist .halfrow {
    flex: 1;
    padding: 0 16px;
}

.filter-popup .filterlist .fieldset > .row {
    margin: 0 -16px;
    align-items: center;
}

.filter-popup .filterlist .fieldset > .row > .divider {
    width: 16px;
    height: 16px;
    position: relative;
}

.filter-popup .filterlist .fieldset > .row > .divider:before {content: "";position: absolute;width: 10px;height: 1px;background: #222;top: 50%;left: 3px;}
.css-eg0mwd-MuiSlider-thumb{
    width:10px !important;
    height: 10px  !important;
}
.css-14pt78w-MuiSlider-rail {
    background: #ECECEC;
    border-radius: 9px;
    opacity: 1;
}
.filter-popup .filterlist .filter-accordion .accordion-content  span.MuiSlider-sizeMedium {
    padding: 6px 0;
    margin-bottom: 6px;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    position: relative;
    top: -2px;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.0);
    border-radius: 0px;
    background-color: transparent;
}

::-webkit-scrollbar {
    width: 5px;
    height: 4px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    border-radius: 100px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .00);
    background-color: #c1c1c1;
}
.filter-popup .filterlist .fieldset {
    margin-bottom: 0;
    padding-bottom: 12px;
    border-bottom: 1px solid #EBEBEB;
}
.filter-popup .drawer-head .drawer-title a.text {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #B6B6B6;
    text-decoration: none;
}