.activity-drawer {
    z-index: 98 !important;
}

.activity-drawer .MuiPaper-elevation16 {
    box-shadow: none;
    background: #f8fafb;
    border: 1px solid #edeef1;
    background: transparent;
    border: none;
    top: 48px;
    min-height: calc(100vh - 74px);
    max-height: calc(100vh - 74px);
    top: 50px;
}

.activity-drawer .activity-popup {
    min-height: calc(100vh - 74px);
    max-height: calc(100vh - 74px);
    background: #f8fafb;
    border: 1px solid #edeef1;
    display: flex;
    flex-direction: column;
    border-left: 2px solid #d2d2d2;
}

.skeltron-head {
    height: 35px;
}

.skeltron-body {
    justify-content: flex-start;
    width: 100%;
    background: #F5F5F5;
    padding: 5px;
    border-radius: 4px;
}

.activity-drawer .activity-popup .activity-head {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px;
    background: #ffffff;
    flex-shrink: 0;
}

.activity-drawer .activity-popup .activity-list {
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    padding: 12px;
    flex: 1;
}

.activity-drawer .activity-popup .activity-head .dropdown .link {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.activity-drawer .activity-popup .activity-head .righ-container {
    flex: 1;
    margin-left: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.activity-drawer .activity-popup .activity-head .righ-container>div {
    margin-left: 8px !important;
}

.activity-drawer .activity-popup .activity-head .righ-container .iconbutton button {
    height: 24px;
    width: 28px;
}

.activity-drawer .activity-popup .activity-head .dropdown .dropdown-menu {
    right: auto !important;
    left: 0 !important;
    top: 100% !important;
}

.activity-drawer .activity-popup .activity-list .item {
    background: #ffffff;
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 12px;
}

.activity-drawer .activity-popup .activity-list .item .tag-line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
}

.activity-drawer .activity-popup .activity-list .item .details {}

.activity-drawer .activity-popup .activity-list .item .tag-line .tag {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    border-radius: 4px;
    padding: 2px 8px;
}

.activity-drawer .activity-popup .activity-list .item .tag-line .dropdown a {
    cursor: pointer;
}

.activity-drawer .activity-popup .activity-list .item .tag-line .dropdown .dropdown-menu {
    top: 100% !important;
}

.activity-drawer .activity-popup .activity-list .item .details .link {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 4px;
    display: inline-block;
}

.activity-drawer .activity-popup .activity-list .item .details .date-time {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.activity-drawer .activity-popup .activity-list .item .details .date-time .date,
.activity-drawer .activity-popup .activity-list .item .details .date-time .time {
    margin-right: 8px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #222222;
}

.activity-drawer .activity-popup .activity-list .item .details .date-time .date .icon,
.activity-drawer .activity-popup .activity-list .item .details .date-time .time .icon {
    position: relative;
    top: 2px;
    margin-right: 4px;
}

.activity-drawer .activity-popup .activity-list .item .details .attributes {}

.activity-drawer .activity-popup .activity-list .item .details .attributes .attribute-list {}

.activity-drawer .activity-popup .activity-list .item .details .attributes .attribute-list .sub {
    font-weight: normal;
    font-size: 12.5px;
    line-height: 20px;
    color: #555555;
    min-width: 65px;
    display: inline-block;
}

.activity-drawer .activity-popup .activity-list .item .details .attributes .attribute-list .dot {
    font-weight: normal;
    font-size: 12.5px;
    line-height: 20px;
    color: #555555;
    margin: 0 4px;
}

.activity-drawer .activity-popup .activity-list .item .details .attributes .attribute-list .text {
    font-weight: normal;
    font-size: 12.5px;
    line-height: 20px;
    color: #222222;
}

.activity-drawer .activity-popup .activity-list .item .tag-line .dropdown {
    line-height: 20px;
}

.tag-line .tag.grey {
    background: #888888;
}

.tag-line .tag.red {
    background: #df6363;
}

.activity-drawer .activity-popup .activity-list .item:last-child {
    margin-bottom: 0;
}

.activity-drawer .activity-popup .activity-head p.head {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #222222;
    margin: 0;
    text-align: left;
    width: 100%;
}

.inner-navmenu .navmenu-list ul {
    z-index: 97 !important;
}

.activity-list.timeline-list .item-head {
    margin-bottom: 10px;
    position: relative;
    z-index: 2;
}

.activity-list.timeline-list .timeline-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.activity-list.timeline-list .item-head .date {
    font-weight: normal;
    font-size: 11px;
    line-height: 16px;
    text-align: center;
    color: #222222;
    background: #e0e5f3;
    border-radius: 4px 12px;
    padding: 1px 12px;
    width: 85px;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.activity-list.timeline-list .timeline-item .icon-box {
    display: inline-block;
    width: 40px;
    height: 40px;
    background: #ffffff;
    border: 1px solid #edeef1;
    box-sizing: border-box;
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.activity-list.timeline-list .timeline-item .item-box {
    background: #ffffff;
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    padding: 12px;
    flex: 1;
}

.activity-list.timeline-list .timeline-item .item-box .flex-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.activity-list.timeline-list .timeline-item .item-box p.text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin: 0;
    word-break: break-word;
}

.activity-list.timeline-list .timeline-item .item-box .flex-block p.type {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 4px;
}

.activity-list.timeline-list .timeline-item .item-box .flex-block .right-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.activity-list.timeline-list .timeline-item .item-box .flex-block .right-container .datetime {
    font-weight: normal;
    font-size: 12.5px;
    line-height: 20px;
    text-align: center;
    color: #555555;
    margin-right: 8px;
}

.activity-list.timeline-list .timeline-item .item-box .flex-block .right-container .dropdown {
    line-height: 20px;
}

.activity-list.timeline-list .item-head:before {
    content: "";
    position: absolute;
    left: 0;
    top: 13px;
    height: 1px;
    width: 100%;
    background: #edeef1;
}

.activity-list.timeline-list:before {
    content: "";
    position: absolute;
    left: 31px;
    top: 0;
    width: 1px;
    height: 100%;
    background: #edeef1;
}

.activity-list.timeline-list .timeline-item .item-box .flex-block .right-container .dropdown>a {
    position: relative;
    top: 1px;
    cursor: pointer;
}

.activity-drawer .activity-popup .activity-head .link .icon {
    margin-right: 6px;
    position: relative;
    top: 1px;
}

.activity-drawer .activity-popup .activity-head>.link {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.activity-drawer .activity-popup .activity-head .link .icon svg {
    height: 11px;
}

.activity-drawer .activity-popup .activity-head .link .icon {
    margin-right: 6px;
    position: relative;
    top: 1px;
}

.activity-drawer .activity-popup .activity-head>.link {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
}

.activity-drawer .activity-popup .activity-head .link .icon svg {
    height: 11px;
}

.activity-list.activity-detail .tag-line {
    margin-bottom: 12px !important;
}

.activity-list.activity-detail .item .details .row-block {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid #EDEEF1;
}

.activity-list.activity-detail .item .details .row-block .text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin: 0;
}

.activity-list.activity-detail .item .details .row-block .attribute {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.activity-list.activity-detail .item .details .row-block .attribute .sub {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #555555;
}

.activity-list.activity-detail .item .details .row-block .attribute .text {}

.activity-list.activity-detail .item .details .row-block .attribute a.text .icon {
    position: relative;
    top: -1px !important;
    margin-left: 6px;
}

.activity-list.activity-detail .item .details .row-block .attribute .text .icon {
    margin-left: 0;
    margin-right: 6px;
    top: 2px !important;
    position: relative;
}

.activity-list.activity-detail .item .details .row-block .fieldset.checkbox-box .input-group input {
    position: relative;
    top: 5px;
    margin-right: 8px;
}

.activity-list.activity-detail .item .details .row-block .text-block {
    display: flex;
    align-items: center;
}

.activity-list.activity-detail .item .details .row-block .text-block .sub {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #555555;
    margin-right: 4px;
}

.activity-list.activity-detail .item .details .row-block .text-block .t-box {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    color: #707070;
    margin-right: 6px;
    padding-right: 6px;
    position: relative;
}

.activity-list.activity-detail .item .details .row-block .attribute .sub .icon-padding {
    position: relative;
    top: 1px;
}

.activity-list.activity-detail .item .details .row-block .fieldset.checkbox-box>label.control-label {
    display: none;
}

.activity-list.activity-detail .item .details .row-block .fieldset.checkbox-box .input-group label {
    margin: 0;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #222222;
}

.activity-list.activity-detail .item .details .row-block .attribute .dropdown>a {
    font-size: 14px;
    line-height: 20px;
}

.activity-list.activity-detail .item .details .row-block .text-block .t-box:before {
    content: "";
    position: absolute;
    right: 0;
    top: 20%;
    width: 1px;
    height: 60%;
    background: #707070;
}

.activity-list.activity-detail .item .details .row-block .text-block .t-box:last-child:before {
    background: transparent;
}

.activity-list.activity-detail .item .details .row-block:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}

.activity-list.activity-detail .item .details .row-block .attribute .dropdown .dropdown-menu {
    bottom: 100% !important;
    top: auto !important;
    left: 0% !important;
    transform-origin: bottom left;
}

.activity-create .MuiDialog-container .MuiPaper-elevation24 {
    width: 800px !important;
    max-width: 75%;
    /* max-height: 600px !important ; */
}

.activity-create .activity-form {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
}

.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 form>div {
    padding: 16px 24px;
    max-height: 440px !important;
}

.activity-create .activity-form .fieldset {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 12px;
}

.activity-create .MuiDialogActions-spacing .item {
    width: auto !important;
}

.activity-create .activity-form .fieldset:nth-child(2n) {
    padding-left: 8px;
}

.activity-create .activity-form .fieldset:nth-child(2n + 1) {
    padding-right: 8px;
}

.activity-create .activity-form .fieldset.checkbox-box .input-group input {
    position: relative;
    top: 6px;
    margin-right: 6px;
    cursor: pointer;
}

.activity-create .activity-form .fieldset.checkbox-box .input-group label.control-label {
    margin: 0;
    font-weight: normal;
    font-size: 13px;
    line-height: 24px;
    color: #222222;
    cursor: pointer;
}

@media (max-width: 1366px) {
    .activity-create .MuiDialog-container .MuiPaper-elevation24 {
        max-height: calc(100vh - 100px);
    }
}

.activity-drawer.notification-drawer {
    z-index: 99 !important;
}

.activity-list.timeline-list.emptycontent:before {
    background: transparent;
}

.activity-list.timeline-list.emptycontent .fullwidth {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    width: 100%;
}

.activity-list.timeline-list.emptycontent .fullwidth .icon-box {
    margin-bottom: 30px;
}

.activity-list.timeline-list.emptycontent .fullwidth p.text.bold {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

.activity-list.timeline-list.emptycontent .fullwidth p.text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #555555;
    margin-bottom: 6px;
}

.activity-list.timeline-list.emptycontent:before {
    background: transparent;
}

.activity-list.timeline-list.emptycontent .fullwidth {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 !important;
    width: 100%;
}

.activity-list.timeline-list.emptycontent .fullwidth .icon-box {
    margin-bottom: 30px;
}

.activity-list.timeline-list.emptycontent .fullwidth p.text.bold {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
}

.activity-list.timeline-list.emptycontent .fullwidth p.text {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #555555;
    margin-bottom: 6px;
}

.activity-list.conversation-wrapper {
    padding: 0 !important;
}

.activity-list.conversation-wrapper ul.list-block {
    padding: 12px;
}

.activity-list.conversation-wrapper ul.list-block li {
    background: #ffffff;
    box-shadow: 0px 8px 28px rgba(0, 0, 0, 0.07);
    border-radius: 8px;
    border-bottom: none;
    margin-bottom: 12px;
}

.activity-list.conversation-wrapper ul.list-block li:last-child {
    margin-bottom: 0;
}

.activity-list.conversation-content .conversation-wrapper .list-block {
    padding: 0;
    background: #fff;
    border-radius: 8px;
}

.activity-list.conversation-content .conversation-wrapper .list-block .item {
    padding: 16px 0;
    box-shadow: none;
    margin: 0 16px;
}

.conversation-content .conversation-wrapper .list-block .item .right-container .dropdown {
    position: relative;
    top: 2px;
    cursor: pointer;
}

.activity-drawer .activity-popup .activity-head .righ-container button.btnsml.min_width {
    width: 28px;
    padding: 0 !important;
}

.activity-drawer .activity-popup .activity-head .righ-container button.btnsml.min_width svg {
    width: 14px;
}

.activity-list.activity-detail .item .details .row-block.full-block {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 0;
}

.activity-list.activity-detail .item .details .row-block.full-block .attribute {
    margin-bottom: 12px;
}

.activity-list.activity-detail .item .details .row-block .attribute .dropdown .dropdown-menu:before {
    border-top: 10px solid #FFE;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: none;
    top: auto !important;
    bottom: -10px;
    left: 10px;
    right: auto !important;
}

.activity-drawer .activity-popup .activity-head .dropdown.leftdrop .dropdown-menu {
    right: 0 !important;
    left: auto !important;
    top: calc(100% + 10px) !important;
}

.popup-stretch-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup-stretch-block .empty-content {
    flex-direction: column;
}

.popup-stretch-block .empty-content .img-box {
    margin-bottom: 8px;
}

.popup-stretch-block .empty-content .text {
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #888888;
}

.activity-list.timeline-list.popup-stretch-block:before {
    background: transparent;
}

.item-box.cursor .type.disabled,
.item-box.cursor .text.disabled {
    cursor: default;
}

.activity-twocolumn .MuiDialog-container .MuiPaper-elevation24 {
    width: 900px !important;
    max-width: 85% !important;
}

.activity-twocolumn .MuiDialog-container .MuiPaper-elevation24 .activity-dialogcontent {
    max-height: calc(100vh - 185px) !important;
    padding: 0 8px 8px;
}

.activity-dialogcontent .row {
    margin: 0 -8px;
}

.activity-dialogcontent .col-md-4.col-xs-12,
.activity-dialogcontent .col-md-6.col-xs-12,
.activity-dialogcontent .col-md-12.col-xs-12,
.activity-dialogcontent .col-md-8.col-xs-12 {
    padding: 0 8px;
}

.assignee-block {
    background: #F8FAFB;
    border: 1px solid #EDEEF1;
    padding: 16px !important;
}

.activity-dialogcontent .activity-wrap>.activity-form {
    padding: 16px 16px 0;
}

.activity-dialogcontent .row {
    margin: 0 -8px;
}

.activity-dialogcontent .col-md-4.col-xs-12,
.activity-dialogcontent .col-md-6.col-xs-12,
.activity-dialogcontent .col-md-12.col-xs-12,
.activity-dialogcontent .col-md-8.col-xs-12 {
    padding: 0 8px;
}

.assignee-block {
    background: #F8FAFB;
    border: 1px solid #EDEEF1;
    padding: 16px !important;
    margin-bottom: 0;
    min-height: 475px;
}

.activity-dialogcontent .activity-wrap>.activity-form {
    padding: 16px 16px 0;
}

.activity-dialogcontent .activity-wrap .activity-form label.control-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #555555;
    text-align: left;
    display: block;
    margin-bottom: 0.5rem;
}

.activity-dialogcontent .activity-wrap>.activity-form .flex-box {}

.activity-dialogcontent .activity-wrap>.activity-form .flex-box .input-flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.activity-dialogcontent .activity-wrap>.activity-form .flex-box .input-flex>.fieldset.selectbox-fieldset {
    min-width: 140px;
    flex: unset;
}
.activity-dialogcontent .activity-wrap>.activity-form .flex-box .input-flex>.fieldset.selectbox-fieldset button.btn.no-margin {top: 8px !important;}

.activity-dialogcontent .activity-wrap>.activity-form .flex-box .input-flex .fieldset {
    margin: 0;
    flex: 1;
}

.activity-dialogcontent .activity-wrap>.activity-form .fieldset {
    margin-bottom: 0 !important;
}

.activity-dialogcontent .activity-wrap>.activity-form .flex-box .input-flex .fieldset label.control-label {
    margin: 0;
}

.activity-dialogcontent .activity-wrap>.activity-form .flex-box .input-flex>.fieldset.selectbox-fieldset .form-control {
    background: #F8FAFB;
    border-radius: 4px 0 0 4px;
}

.activity-dialogcontent .activity-wrap>.activity-form .flex-box .input-flex .fieldset input.form-control {
    border-radius: 0 4px 4px 0;
    box-shadow: none;
}

.activity-wrap .activity-form .col-md-12.col-xs-12,
.activity-wrap .activity-form .col-md-6.col-xs-12,
.activity-wrap .activity-form .col-md-4.col-xs-12,
.activity-wrap .activity-form .col-md-8.col-xs-12 {
    margin-bottom: 12px;
}

.fieldset .input-group .form-control::placeholder {
    line-height: 32px;
}

.activity-dialogcontent .assignee-block .activity-item .head {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    margin-bottom: 8px;
}

.activity-dialogcontent .assignee-block .activity-item .fieldset {
    margin-bottom: 12px !important;
}

.activity-dialogcontent .assignee-block .activity-item .assignee-list {
    padding: 0;
    margin-bottom: 0;
    margin-right: -8px;
    padding-right: 8px;
    max-height: 185px;
    overflow-y: auto;
}
.assignee-block .activity-item {border-bottom: 1px solid #EDEEF1;margin-bottom: 16px;padding-bottom: 16px;}

.assignee-block .activity-item:last-child {border-bottom: none;margin-bottom: 0;padding-bottom: 0;}

.assignee-block .activity-item ul.assignee-list {margin-top: 12px;}

.activity-dialogcontent .assignee-block .activity-item .assignee-list li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 6px;
    max-height: 104px;
    overflow-y: auto;
}

.activity-dialogcontent .assignee-block .activity-item .assignee-list .img-box {
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    overflow: hidden;
    margin-right: 10px;
}

.activity-dialogcontent .assignee-block .activity-item .assignee-list .text {
    font-weight: normal;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #222222;
    margin-bottom: 0;
    flex: 1;
}

.activity-dialogcontent .assignee-block .activity-item .assignee-list .img-box img {
    width: 100%;
    display: block;
}

.activity-dialogcontent .activity-wrap.row {
    border-top: 1px solid #EDEEF1;
}

.activity-dialogcontent .activity-wrap .activity-form.col-md-8.col-xs-12 {
    padding-bottom: 0;
    margin-bottom: 4px;
}

.activity-dialogcontent .activity-wrap.row .activity-form.col-md-12.col-xs-12 {
    margin-top: 16px;
}

.activity-twocolumn .MuiDialog-container .MuiPaper-elevation24 .MuiDialogActions-spacing {
    padding-top: 16px !important;
    border-top: 1px solid #EDEEF1;
}

.activity-list.timeline-list .timeline-item .item-box .flex-block p.type .icon {
    position: relative;
    top: 2px;
    margin-left: 8px;
}

.tag-line.tag-flex .att-item {
    flex: 1;
    text-align: right;
    font-weight: normal;
    font-size: 12.5px;
    line-height: 20px;
    margin-right: 8px;
}

.attributes.attribute-column {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #F5F5F5;
    border-radius: 4px;
    padding: 2px;
}

.attributes.attribute-column .attribute-list {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.attributes.attribute-column .attribute-list .date {
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #222222;
}

.attributes.attribute-column .attribute-list .date .icon {
    position: relative;
    top: 2px;
    margin-right: 8px;
}

.attributes.attribute-column .attribute-list:before {
    content: "";
    position: absolute;
    right: 0;
    top: 20%;
    height: 60%;
    width: 1px;
    background: #BEBEBE;
}

.attributes.attribute-column .attribute-list:last-child:before {
    background: transparent;
}

.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 form>div .fieldset textarea.form-control {
    min-height: 120px;
    height: 120px;
    white-space: pre-wrap;
}
.activity-drawer .activity-popup .activity-list .item {
    transition: all 0.25s ease;
    position: relative;
    left: 0;
}

.activity-drawer .activity-popup .activity-list .item:hover {
    transition: all 0.25s ease;
    box-shadow: 0px 8px 68px rgb(0 0 0 / 27%);
    left: -1px;
}
.activity-list.timeline-list .timeline-item .item-box {
    transition: all 0.25s ease;
    position: relative;
    left: 0;
}

.activity-list.timeline-list .timeline-item:hover .item-box {
    transition: all 0.25s ease;
    box-shadow: 0px 8px 68px rgb(0 0 0 / 27%);
    left: -1px;
}
.activity-list.activity-detail .item .details .row-block .text.pre_wrap {
    white-space: pre-wrap;
}
.activity-list.timeline-list .timeline-item .item-box a {
    text-decoration: none !important;
}
.not_loader {margin: 6px;width: 100%;display: flex;align-items: center;justify-content: center;}
.assignee-block .activity-item .MuiFormControl-fullWidth {margin: 0;}

.assignee-block .activity-item .MuiFormControl-fullWidth > div {background: #FFFFFF !important;

border: 1px solid #CACEDA !important;

border-radius: 4px !important}

.assignee-block .activity-item .MuiFormControl-fullWidth > div > input {padding: 0 8px;height: 32px;font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 18px;}

.assignee-block .activity-item .MuiFormControl-fullWidth > div > input::placeholder {font-size: 13px;}
.activity-dialogcontent .assignee-block .activity-item .assignee-list li .close_icon {padding-left: 6px;opacity: 0;transition: all 0.2s ease;cursor: pointer;}

.activity-dialogcontent .assignee-block .activity-item .assignee-list li:hover .close_icon {opacity: 1;transition: all 0.2s ease;}
.custom_Popup_Height .assignee-block {
    min-height: 305px;
}
.custom_Popup_Height {}

.custom_Popup_Height .assignee-block {
    min-height: 305px;
}

.flex_Rows {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.flex_Rows .flex_Child {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 8px;
    margin-bottom: 4px;
}

.flex_Rows .flex_Child .icon {
    line-height: 13px;
    margin-right: 4px;
}

.flex_Rows .flex_Child span {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    min-width: 50px;
}

.flex_Column {}

.flex_Column .flex_Child {
    line-height: 15px;
}

.flex_Column .flex_Child .sub {
    font-weight: 400;
    font-size: 12.5px;
    line-height: 18px;
    color: #555555;
}

.flex_Column .flex_Child .text {
    font-weight: 400;
    font-size: 12.5px;
    line-height: 18px;
    color: #222222;
    margin-left: 4px;
}
.dropdown.drop_left .dropdown-menu:before {
    left: 8px;
    right: unset;
}
.tag.tab_bordered {
    border-width: 1px;
    border-style: solid;
}
.dropdown.drop_left .dropdown-menu {
    transform-origin: top left;
}
.dropdown.drop_left .dropdown-menu {
    transform-origin: top left;
}

p.control-label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #333333;
    margin-bottom: 8px !important;
}

.attachment_List {}

.attachment_List .attachment_Item {
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 4px;
}

.attachment_List .attachment_Item .icon {
    margin-right: 4px;
}

.attachment_List .attachment_Item p.text {
    flex: 1;
    word-break: break-all;
    padding-right: 12px;
}

.attachment_List .attachment_Item a.close_icon {
    opacity: 0;
    transition: all 0.2s ease;
}

.attachment_List .attachment_Item:hover a.close_icon {
    opacity: 1;
    transition: all 0.2s ease;
}
.tag-line.tag-flex.attachment_Flex {
    align-items: flex-start !important;
}

.tag-line.tag-flex.attachment_Flex .link {
    line-height: 17px !important;
    margin-right: 6px;
}

.tag-line.tag-flex.attachment_Flex .text {
    flex: 1;
    margin-bottom: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #222222;
    padding-right: 10px;
}
.activity-singlecolumn .activity-dialogcontent .activity-wrap .activity-form.col-md-8.col-xs-12 {
    flex: 0 0 100%;
    max-width: 100%;
}
.activity-singlecolumn.MuiModal-root .MuiDialog-container .MuiPaper-elevation24 {
    width: 800px;
}

.attach_Flex {
    width: 100%;
}

.attach_Flex .attachment-list {
    margin-bottom: 4px;
    width: 100%;
}

.attach_Flex .attachment-list .list-item {
    display: flex;
    align-items: center;
    padding: 6px;
    width: 100%;
    border-radius: 4px;
}

.attach_Flex .attachment-list .list-item .icon {
    margin-right: 8px;
    cursor: pointer;
}

.attach_Flex .attachment-list .list-item p.list-item {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
    word-break: break-all;
    padding: 0;
    padding-right: 12px;
}

.attach_Flex .attachment-list .list-item .icon svg {
    width: 15px;
}

.attach_Flex p.text.bold {
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: #333333 !important;
    margin-bottom: 8px !important;
}
.attach_Flex .attachment-list .list-item a.close-icon {
    opacity: 0;
    transition: all 0.2s ease;
}

.attach_Flex .attachment-list .list-item:hover a.close-icon {
    opacity: 1;
    transition: all 0.2s ease;
}